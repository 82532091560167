import {
  FormLabel,
  Grid,
  Input,
  TextField,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";

export const ViewAndEditEntity = ({
  handleInputChange,
  index,
  row,
  viewMode,
  validationStatus,
}) => {
  //inline style
  const InlineStyle = {
    EntityDivison: {
      width: "230px",
      height: "32",
      padding: "0px",
    },
    premium: {
      position: "relative",
      width: "120px",
      height: "35px",
      minWidth: "120px",
      maxHeight: "35px",
    },
  };
  return (
    <form>
      <Grid container justifyContent={"space-between"}>
        <Grid
          item
          xs={3}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Region</FormLabel>
          <TextField
            value={row.region}
            onChange={(e) => handleInputChange(index, "region", e.target.value)}
            sx={InlineStyle.EntityDivison}
            size="small"
            placeholder="Enter Details Here"
            disabled={viewMode}
          />
          <br />
          {validationStatus[index]?.region && (
            <span style={{ color: "red" }}>
              {validationStatus[index]?.region}
            </span>
          )}
        </Grid>
        <Grid
          item
          xs={3}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Entity Number</FormLabel>
          <TextField
            value={row.entity_number}
            onChange={(e) =>
              handleInputChange(index, "entity_number", e.target.value)
            }
            sx={InlineStyle.EntityDivison}
            size="small"
            placeholder="Enter Details Here"
            disabled={viewMode}
          />
          <br />
          {validationStatus[index]?.entity_number && (
            <span style={{ color: "red" }}>
              {validationStatus[index]?.entity_number}
            </span>
          )}
        </Grid>
        <Grid
          item
          xs={3}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>MSD Entity Number</FormLabel>
          <TextField
            value={row.msd_entity_number}
            onChange={(e) =>
              handleInputChange(index, "msd_entity_number", e.target.value)
            }
            sx={InlineStyle.EntityDivison}
            size="small"
            placeholder="Enter Details Here"
            disabled={viewMode}
          />
          <br />
          {validationStatus[index]?.msd_entity_number && (
            <span style={{ color: "red" }}>
              {validationStatus[index]?.msd_entity_number}
            </span>
          )}
        </Grid>
        <Grid
          item
          xs={3}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Enitity Name</FormLabel>
          <TextField
            value={row.entity_name}
            onChange={(e) =>
              handleInputChange(index, "entity_name", e.target.value)
            }
            sx={InlineStyle.EntityDivison}
            size="small"
            placeholder="Enter Details Here"
            disabled={viewMode}
          />
          <br />
          {validationStatus[index]?.entity_name && (
            <span style={{ color: "red" }}>
              {validationStatus[index]?.entity_name}
            </span>
          )}
        </Grid>

        <Grid
          item
          xs={3}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Bank Name</FormLabel>
          <TextField
            value={row.bank_name}
            onChange={(e) =>
              handleInputChange(index, "bank_name", e.target.value)
            }
            sx={InlineStyle.EntityDivison}
            size="small"
            placeholder="Enter Details Here"
            disabled={viewMode}
          />
          <br />
          {validationStatus[index]?.bank_name && (
            <span style={{ color: "red" }}>
              {validationStatus[index]?.bank_name}
            </span>
          )}
        </Grid>
        <Grid
          item
          xs={3}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Account Number</FormLabel>
          <TextField
            value={row.account_number}
            onChange={(e) =>
              handleInputChange(index, "account_number", e.target.value)
            }
            sx={InlineStyle.EntityDivison}
            size="small"
            placeholder="Enter Details Here"
            disabled={viewMode}
          />
          <br />
          {validationStatus[index]?.account_number && (
            <span style={{ color: "red" }}>
              {validationStatus[index]?.account_number}
            </span>
          )}
        </Grid>
        <Grid
          item
          xs={3}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Opening Date</FormLabel>
          <Input
            type="date"
            value={row?.account_opening_date?.substring(0, 10) ?? ""}
            onChange={(e) =>
              handleInputChange(index, "account_opening_date", e.target.value)
            }
            // style={InlineStyle.PaymentReceiveDate}
            placeholder="Enter Details Here"
            disabled={viewMode}
            style={{ width: "70%" }}
          />
          <br />

          {validationStatus[index]?.account_opening_date && (
            <span style={{ color: "red" }}>
              {validationStatus[index]?.account_opening_date}
            </span>
          )}
        </Grid>
        <Grid
          item
          xs={3}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Account Type</FormLabel>
          <TextField
            value={row.account_type}
            onChange={(e) =>
              handleInputChange(index, "account_type", e.target.value)
            }
            sx={InlineStyle.EntityDivison}
            size="small"
            placeholder="Enter Details Here"
            disabled={viewMode}
          />
          <br />
          {validationStatus[index]?.account_type && (
            <span style={{ color: "red" }}>
              {validationStatus[index]?.account_type}
            </span>
          )}
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Currency</FormLabel>
          <TextField
            value={row.currency}
            onChange={(e) =>
              handleInputChange(index, "currency", e.target.value)
            }
            sx={InlineStyle.EntityDivison}
            size="small"
            placeholder="Enter Details Here"
            disabled={viewMode}
          />
          <br />
          {validationStatus[index]?.currency && (
            <span style={{ color: "red" }}>
              {validationStatus[index]?.currency}
            </span>
          )}
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>MSD Account Number</FormLabel>
          <TextField
            value={row.msd_acct_number}
            onChange={(e) =>
              handleInputChange(index, "msd_acct_number", e.target.value)
            }
            sx={InlineStyle.EntityDivison}
            size="small"
            placeholder="Enter Details Here"
            disabled={viewMode}
          />
          <br />
          {validationStatus[index]?.msd_acct_number && (
            <span style={{ color: "red" }}>
              {validationStatus[index]?.msd_acct_number}
            </span>
          )}
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>MSD Account Name</FormLabel>
          <TextField
            value={row.msd_acct_name}
            onChange={(e) =>
              handleInputChange(index, "msd_acct_name", e.target.value)
            }
            sx={InlineStyle.EntityDivison}
            size="small"
            placeholder="Enter Details Here"
            disabled={viewMode}
          />
          <br />
          {validationStatus[index]?.msd_acct_name && (
            <span style={{ color: "red" }}>
              {validationStatus[index]?.msd_acct_name}
            </span>
          )}
        </Grid>
        <Grid
          item
          xs={4}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Prime Bank Account</FormLabel>

          {viewMode ? (
            <TextField
              value={row.prime_bank_account}
              onChange={(e) =>
                handleInputChange(index, "prime_bank_account", e.target.value)
              }
              sx={InlineStyle.EntityDivison}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
            />
          ) : (
            <>
              <Select
                value={row.prime_bank_account}
                onChange={(e) =>
                  handleInputChange(index, "prime_bank_account", e.target.value)
                }
                sx={InlineStyle.premium}
                size="small"
                placeholder="Enter Details Here"
                disabled={viewMode}
                displayEmpty
              >
                <MenuItem value={true}>
                  <ListItemText primary={"true"} />
                </MenuItem>
                <MenuItem value={false}>
                  <ListItemText primary={"false"} />
                </MenuItem>
              </Select>
              <br />
              {validationStatus[index]?.prime_bank_account && (
                <span style={{ color: "red" }}>
                  {validationStatus[index]?.prime_bank_account}
                </span>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
