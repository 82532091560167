import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { AutoCompleteSelect } from "./AutoCompleteSelect"; 

//inline styles
const InlineStyles = {
  TableHeader: {
    backgroundColor: "#262671",
    color: "white",
    position: "sticky",
    top: 0,
    zIndex: 1,
    height: "50px",
  },
  TableCells: {
    color: "black",
    fontSize: "12px",
    height: "44px",
    padding: "0px",
    width: "300px",
  },
  TableBodyRows: {
    border: "1px solid #ddd",
    marginTop: "10px",
    paddingTop: "15px",
    verticalAlign: "top",
  },
  TableBodyRowCells: {
    height: "20px",
    padding: "0px",
  },
  escalationTableCell: {
    height: "30px",
    width: "200px",
    padding: "3px",
  },
  escalation: {
    position: "relative",
    width: "123px",
    height: "35px",
    minWidth: "200px",
    maxHeight:"35px"
  },
  MoreVertIconStyle: {
    color: "FF5A01",
    background: "transparent",
    cursor: "pointer",
    transform: "rotate(90deg)",
  },
};
export const AddEscalation = ({
  statuses,
  handleInputChange,
  row,
  viewMode,
  validationStatus,
  usersList,
  handleEscalationUserChange,
}) => {
  return (
    <Table>
      <TableHead style={InlineStyles.TableHeader}>
        <TableRow style={{ backgroundColor: "#FFC000" }}>
          <TableCell className="TableCell" style={InlineStyles.TableCells}>
            Transaction Type
          </TableCell>
          <TableCell className="TableCell" style={InlineStyles.TableCells}>
            Status
          </TableCell>
          <TableCell className="TableCell" style={InlineStyles.TableCells}>
            Organization
          </TableCell>
          <TableCell className="TableCell" style={InlineStyles.TableCells}>
            Escalation-1
          </TableCell>
          <TableCell className="TableCell" style={InlineStyles.TableCells}>
            Escalation-2
          </TableCell>
          <TableCell className="TableCell" style={InlineStyles.TableCells}>
            Escalation-3
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow style={InlineStyles.TableBodyRows}>
          <TableCell sx={InlineStyles.escalationTableCell}>
            <Select
              value={row.transaction_type}
              onChange={(e) =>
                handleInputChange("transaction_type", e.target.value)
              }
              sx={InlineStyles.escalation}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
              displayEmpty
            >
              <MenuItem
                value={"Bank Transaction"}
              >
                <ListItemText
                  primary={"Bank Transaction"}
                  className="list-item"
                />
              </MenuItem>
              <MenuItem
                value={"Cash Allocation"}
              >
                <ListItemText
                  primary={"Cash Allocation"}
                  className="list-item"
                />
              </MenuItem>
            </Select>
            <br />
            {validationStatus?.transaction_type && (
              <span style={{ color: "red" }}>
                {validationStatus?.transaction_type}
              </span>
            )}
          </TableCell>

          <TableCell sx={InlineStyles.escalationTableCell}>
            <Select
              value={row.status}
              onChange={(e) => handleInputChange("status", e.target.value)}
              sx={InlineStyles.escalation}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
              displayEmpty
            >
              {statuses.map((status) => (
                <MenuItem value={status}>
                  <ListItemText
                    primary={status}
                    className="list-item"
                  />
                </MenuItem>
              ))}
            </Select>
            <br />
            {validationStatus?.status && (
              <span style={{ color: "red" }}>{validationStatus?.status}</span>
            )}
          </TableCell>

          <TableCell sx={InlineStyles.escalationTableCell}>
            <Select
              value={row.organization}
              onChange={(e) =>
                handleInputChange("organization", e.target.value)
              }
              sx={InlineStyles.escalation}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
              displayEmpty
            >
              <MenuItem value={"Mosaic"}>
                <ListItemText
                  primary={"Mosaic"}
                  className="list-item"
                />
              </MenuItem>
              <MenuItem value={"WNS"}>
                <ListItemText
                  primary={"WNS"}
                  className="list-item"
                />
              </MenuItem>
            </Select>

            <br />
            {validationStatus?.organization && (
              <span style={{ color: "red" }}>
                {validationStatus?.organization}
              </span>
            )}
          </TableCell>
          <TableCell sx={InlineStyles.escalationTableCell}>
            <AutoCompleteSelect
              name={"escalation_level_one"}
              placeholder="Select Escalation 1"
              value={row.escalation_level_one}
              handleChange={(event, value, reason) =>
                handleEscalationUserChange(
                  "escalation_level_one",
                  value === "" ? "" : value,
                  reason
                )
              }
              options={usersList || []}
            />
            {validationStatus?.escalation_level_one && (
              <span style={{ color: "red" }}>
                {validationStatus?.escalation_level_one}
              </span>
            )}
          </TableCell>

          <TableCell sx={InlineStyles.escalationTableCell}>
            <AutoCompleteSelect
              name={"escalation_level_two"}
              placeholder="Select Escalation-2"
              value={row.escalation_level_two}
              handleChange={(event, value, reason) =>
                handleEscalationUserChange(
                  "escalation_level_two",
                  value === "" ? "" : value,
                  reason
                )
              }
              options={usersList || []}
            />
            {validationStatus?.escalation_level_two && (
              <span style={{ color: "red" }}>
                {validationStatus?.escalation_level_two}
              </span>
            )}
          </TableCell>

          <TableCell sx={InlineStyles.escalationTableCell}>
            <AutoCompleteSelect
              name={"escalation_level_three"}
              placeholder="Select Escalation-3"
              value={row.escalation_level_three}
              handleChange={(event, value, reason) =>
                handleEscalationUserChange(
                  "escalation_level_three",
                  value === "" ? "" : value,
                  reason
                )
              }
              options={usersList || []}
            />
            {validationStatus?.escalation_level_three && (
              <span style={{ color: "red" }}>
                {validationStatus?.escalation_level_three}
              </span>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
