import React, { useState, useEffect, useRef } from "react";
import {
  Tooltip,
  Stack,
  Input,
  Grid,
  MenuItem,
  Checkbox,
  Button,
  Paper,
  Menu,
  Fade,
  ListItemText,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
} from "@mui/material";
import format from "date-fns/format";
import { toast } from "react-toastify";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { CommonButton } from "../common/commonButton";
import { useStyles } from "./paymentUpdatesContainerStyles";
import { CommonPaginationContainer } from "../common";
import instance from "../../redux/api";
import { exceptionReport } from "../../Services/api";
import "./bdx.css";
import ROUTES from "../../utils/routes";
import ExceptionReportDetails from "./ExceptionReportDetails";
import { getPaginationCount } from "../CashAllocation/utils";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";


export const inputStyleObj = {
  border: "2px solid #ccc",
  padding: 2,
  paddingLeft: 16,
  fontSize: 13,
  height: 38,
  width: "260px",
  borderRadius: 5,
};
export const selectStyleObj = {
  border: "1px solid #ccc",
  padding: 2,
  paddingLeft: 16,
  fontSize: 13,
  width: "100%",
  height: 38,
  borderRadius: 5,
};
export const filterInputStyleObj = {
  border: "2px solid #ccc",
  padding: 2,
  paddingLeft: 16,
  fontSize: 13,
  height: 38,
  width: "100%",
  borderRadius: 5,
};
const options = {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

function handleAmount(val) {
  return (
    (Number(val) && Number(val).toLocaleString("en-US", options)) ||
    (val === 0 && parseInt(val).toFixed(2)) ||
    val
  );
}

const ExceptionReport = () => {
  const { userData } = useSelector((state) => state?.user);
  const [searchFieldsParams, setSearchFieldsParams] = useState({
    certificate_ref: "",
    bdx_file_name: "",
    overall_status: [
      // "Investigate Investigate",
      // "Investigate Initiate Payment",
      // "Investigate Partner Payment Done",
      // "Investigate Payment Fully Done"
    ],
  });

  const [paginationCount, setPaginationCount] = useState(0);

  const toggle = useSelector((state) => state.toggleSideMenu);
  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [isClearPerformed, setIsClearPerformed] = useState(false);

  const [selectedParams, setSelectedParams] = useState({
    type_of_payment: "",
    certificate_ref: "",
    bdx_file_name: "",
  });
  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
        ? pageState?.page
        : pageState?.page - 1;
  };
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [overallStatuses, setOverallStatuses] = useState([]);
  const openMenu = Boolean(anchorEl);
  const [openCommentsDialog, setOpenCommentsDialog] = useState(null);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [dialogCommentsValue, setDialogCommentsValue] = useState("");
  const [commentType, setCommentType] = useState(null);

  const isFirstRun = useRef(true);

  useEffect(() => {
    getOverallStatusList();
  }, []);

  //styles
  const classes = useStyles();

  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  useEffect(() => {
    handleSearchPaymentUpdatesList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFieldsParams.overall_status])

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (!value) {
      handleClearSearchList()
    }
    setSearchFieldsParams((v) => ({
      ...v,
      [name]: value,
    }));
  };

  const getOverallStatusList = async () => {
    const response = await instance.get(
      "payment/payment_overall_status/overall_status/"
    );
    const overall_status_list = response.data.data;
    setOverallStatuses(overall_status_list);
    let selectedOverallStatus = [];
    for (let overall_status of overall_status_list) {
      if (overall_status.toLowerCase().includes("investigate")) {
        selectedOverallStatus.push(overall_status);
      }
    }
    setSearchFieldsParams((old) => ({
      ...old,
      overall_status: selectedOverallStatus,
    }));
  };

  const searchQueryParams = () => {
    let getSearchQueryParams = "";

    const { certificate_ref, bdx_file_name, overall_status } =
      searchFieldsParams;

    if (certificate_ref) {
      getSearchQueryParams = `${getSearchQueryParams}&certificate_ref=${certificate_ref}`;
    }

    if (bdx_file_name) {
      getSearchQueryParams = `${getSearchQueryParams}&bdx_file_name=${bdx_file_name}`;
    }

    if (overall_status?.length) {
      const overallStatusStr = overall_status.join(",");
      getSearchQueryParams = `${getSearchQueryParams}&overall_status=${overallStatusStr}`;
    }

    return getSearchQueryParams;
  }

  const handleSearchPaymentUpdatesList = async () => {
    try {
      let queryParams = `?skip=0&pageSize=${pageState?.pageSize}`;

      const getSearchQueryParam = searchQueryParams();
      if (getSearchQueryParam) {
        queryParams = `${queryParams}${getSearchQueryParam}`;
      }

      // set loading indicator
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const response = await instance.get(`${exceptionReport}${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      setIsSearchPerformed(true);
      setIsClearPerformed(false);

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ?? [],
          page: 1,  // required for sending skip as 0
        }));
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (error) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.log("error", error);
    }
  };

  const handleClearSearchList = async () => {
    setIsSearchPerformed(false);
    setIsClearPerformed(true);

    // clear fields
    setSearchFieldsParams({
      certificate_ref: "",
      bdx_file_name: "",
      overall_status: [],
    });
  };

  const isFilterBtnDisable = () => {
    let disabled = true;
    const { certificate_ref, bdx_file_name, overall_status } =
      searchFieldsParams;
    if (
      certificate_ref !== "" ||
      bdx_file_name !== "" ||
      overall_status.length
    ) {
      disabled = false;
    }
    return disabled;
  };

  const loadData = async () => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      let investigate_str = "";
      let queryParams = ``;
      if (searchFieldsParams.overall_status.length) {
        const overallStatusStr = searchFieldsParams.overall_status.join(",");
        queryParams = `${queryParams}&overall_status=${overallStatusStr}`;
      } else if (isFirstRun.current) {
        investigate_str = "investigate";
        queryParams = `&overall_status_contains=${investigate_str}`;
        isFirstRun.current = false;
      }
      // add search query param if the search is enabled
      if (isSearchPerformed) {
        const getSearchQueryParam = searchQueryParams();
        if (getSearchQueryParam) {
          queryParams = `${queryParams}${getSearchQueryParam}`;
        }
      }

      const response = await instance.get(
        `${exceptionReport}?skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize
        }${queryParams}`
      );

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ?? [],
        }));
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.error("err", err);
    }
  };

  const handleCloseMenu = (index) => {
    setAnchorEl(null);
  };

  const handleClickMenu = (event, params) => {
    setSelectedParams((old) => ({
      ...old,
      certificate_ref: params?.certificate_ref,
      bdx_file_name: params?.bdx_file_name,
      type_of_payment: params?.cash_bank_ref,
    }));
    setAnchorEl(event.currentTarget);
  };

  const handleCheckboxChange = (event, index) => {
    if (event.target.checked) setSelectedRowIndex(index);
    else setSelectedRowIndex(null);
  };


  const getTreasurySearchPrams = () => {
    let searchParam = "";

    if (selectedParams?.certificate_ref) {
      searchParam = `${searchParam}policyNo=${selectedParams?.certificate_ref}`;
    }
    // if (selectedParams?.bdx_file_name) {
    //   searchParam = `${searchParam}&fileName=${selectedParams?.bdx_file_name.replace("&", "%26")}`;
    // }
    // if (selectedParams?.type_of_payment) {
    //   searchParam = `${searchParam}&paymentType=${selectedParams?.type_of_payment}`;
    // }

    return searchParam;
  }

  const PremiumBDXSearchPrams = () => {
    let searchParam = "";

    if (selectedParams?.certificate_ref) {
      searchParam = `${searchParam}policyNo=${selectedParams?.certificate_ref}`;
    }
    if (selectedParams?.bdx_file_name) {
      searchParam = `${searchParam}&fileName=${selectedParams?.bdx_file_name.replace("&", "%26")}`;
    }
    return searchParam;
  }

  const columns = [
    {
      headerName: "",
      headerAlign: "center",
      width: 60,
      type: "actions",
      renderCell: (params) => (
        <>
          <Checkbox
            className="table-checkbox"
            checked={selectedRowIndex === params.api.getRowIndex(params.row.id)}
            onChange={(event) =>
              handleCheckboxChange(event, params.api.getRowIndex(params.row.id))
            }
          />
        </>
      ),
    },
    {
      field: "certificate_ref",
      headerName: "Certificate Ref",
      headerAlign: "center",
      headerClassName: "hide-column",
      width: 160,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{}}>{params.row.certificate_ref}</div>
          <Button
            onClick={(event) => handleClickMenu(event, params.row)}
            endIcon={<KeyboardArrowDownIcon style={{ fill: "black" }} />}
            style={{ minWidth: "20px", background: "transparent" }}
          />
          <Paper>
            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={{}} style={{ color: "black" }}>
                <Link
                  to={{
                    pathname: ROUTES.MUCASHTRACKER,
                    search: `?policyNo=${selectedParams.certificate_ref}`,
                  }}
                  target={"_blank"}
                  style={{ color: "black" }}
                >
                  Cash Tracker
                </Link>
              </MenuItem>
              <MenuItem onClick={{}}>
                <Link
                  to={{
                    pathname: ROUTES.TreasuryList,
                    search: `?${getTreasurySearchPrams()}`,
                  }}
                  target={"_blank"}
                  style={{ color: "black" }}
                >
                  Treasury
                </Link>
              </MenuItem>
              <MenuItem onClick={{}}>
                <Link
                  to={{
                    pathname: ROUTES.PremiumBDX,
                    search: `?${PremiumBDXSearchPrams()}`,
                  }}
                  params={{
                    policyNo: params.row.certificateRef,
                    fileName: params.row.bdx_file_name,
                  }}
                  target={"_blank"}
                  style={{ color: "black" }}
                >
                  Premium BDX
                </Link>
              </MenuItem>
            </Menu>
          </Paper>
        </div>
      ),
    },
    {
      field: "bdx_file_name",
      headerName: "BDX File Name",
      headerAlign: "center",
      width: 500,
      renderCell: (params) => (
        <div>
          <Tooltip title={params.row.bdx_file_name}>
            {params.row?.bdx_file_name.length > 100 
                ? `${params.row.bdx_file_name.substring(0, 100)}...` 
                : params.row.bdx_file_name}
          </Tooltip>
        </div>
      ),
    },
    {
      field: "created_at",
      headerName: "Created Date",
      headerAlign: "center",
      width: 170,
      valueFormatter: (params) => {
        return format(new Date(params.value), "dd-MM-yyyy");
      },
    },
    {
      field: "settlement_currency",
      headerName: "Settlement Ccy",
      headerAlign: "center",
      width: 130
    },
    {
      field: "net_of_brokerage",
      headerName: "Net of Brokerage",
      headerAlign: "center",
      width: 130,
      cellClassName: "amount-table-cell",
      renderCell: (params) => <>{handleAmount(params.row.net_of_brokerage)}</>,
    },
    {
      field: "net_premium",
      headerName: "Net Premium",
      headerAlign: "center",
      width: 110,
      cellClassName: "amount-table-cell",
      renderCell: (params) => <>{handleAmount(params.row.net_premium)}</>,
    },
    {
      field: "ct_allocation_amt",
      headerName: "CT Allocation Amt",
      headerAlign: "center",
      width: 135,
      cellClassName: "amount-table-cell",
      renderCell: (params) => <>{handleAmount(params.row.ct_allocation_amt)}</>,
    },
    {
      field: "variance_with_ct",
      headerName: "Variance with CT",
      headerAlign: "center",
      width: 130,
      cellClassName: "amount-table-cell",
      renderCell: (params) => <>{handleAmount(params.row.variance_with_ct)}</>,
    },
    {
      field: "overall_status",
      headerName: "Overall Status",
      headerAlign: "center",
      width: 160,
      cellClassName: "overall-status-cell",
      renderCell: (params) => (
        <div>
          <Tooltip title={params.row?.overall_status}>
            {params.row?.overall_status}
          </Tooltip>
        </div>
      ),
    },
    {
      field: "cash_bdx_amt",
      headerName: "Cash BDx Amt",
      headerAlign: "center",
      width: 115,
      cellClassName: "amount-table-cell",
      renderCell: (params) => <>{handleAmount(params?.row?.cash_bdx_amt)}</>
    },
    {
      field: "non_cash_bdx_amt",
      headerName: "Non Cash BDx Amt",
      headerAlign: "center",
      width: 140,
      cellClassName: "amount-table-cell",
      renderCell: (params) => <>{handleAmount(params?.row?.non_cash_bdx_amt)}</>
    },

    {
      field: "potential_payment_indicator",
      headerName: "Potential Payment Indicator",
      headerAlign: "center",
      width: 170,
    },
    {
      field: "comments_audit",
      headerName: "Comments - Audit",
      headerAlign: "center",
      width: 140,
      renderCell: (params) => (
        <>
          {params?.row?.comments_audit?.substr(0, 15) ?? ""}
          <div>
            <MoreHorizIcon
              onClick={() =>
                handleCommentsOpenDialog(
                  params?.row?.id,
                  params?.row?.comments_audit,
                  "comments_audit"
                )
              }
            />
          </div>
        </>
      ),
    },
    {
      field: "comment",
      headerName: "Comment",
      headerAlign: "center",
      width: 120,
      renderCell: (params) => (
        <>
          {params?.row?.comment?.substr(0, 15) ?? ""}
          <div>
            <MoreHorizIcon
              onClick={() =>
                handleCommentsOpenDialog(
                  params?.row?.id,
                  params?.row?.comment,
                  "comment"
                )
              }
            />
          </div>
        </>
      ),
    },
  ];


  useEffect(() => {
    setPaginationCount(getPaginationCount(pageState?.total, pageState?.pageSize));
  }, [pageState?.pageSize, pageState?.total]);

  // UseEffect
  useEffect(() => {
    if (isClearPerformed) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClearPerformed]);

  useEffect(() => {
    if (!isClearPerformed) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState?.pageSize, pageState?.page]);

  const handleCommentsOpenDialog = (currentRowId, currentRowCommentData, commentType) => {
    setOpenCommentsDialog(true);
    setCurrentRowId(currentRowId);
    setDialogCommentsValue(currentRowCommentData);
    setCommentType(commentType);
  };

  const handleCloseCommentDialog = () => {
    setOpenCommentsDialog(false);
    setDialogCommentsValue("");
    setCurrentRowId(null);
    setCommentType(null);
  };

  const handleDialogInputChange = (e) => {
    setDialogCommentsValue(e.target.value);
  };

  const handleSaveComments = () => {
    if (commentType === "comment") {
      handleCommentsUpdate();
    }
    else if (commentType === "comments_audit") {
      handleAuditCommentsUpdate();
    }
    handleCloseCommentDialog();
  };

  const handleCommentsUpdate = async () => {
    const payload = {
      updated_by: userData.id,
      comment: dialogCommentsValue,
    };
    const response = await instance.patch(
      `/payment/exception_file/${currentRowId}/`,
      payload
    );
    if (response.status === 200) {
      toast.success("Comment successfully saved", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      loadData();
    } else {
      toast.error("Comment could not be saved", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleAuditCommentsUpdate = async () => {
    const payload = {
      updated_by: userData.id,
      comments_audit: dialogCommentsValue,
    };
    const response = await instance.patch(
      `/payment/exception_file/${currentRowId}/`,
      payload
    );
    if (response.status === 200) {
      toast.success("Audit Comment successfully saved", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      loadData();
    } else {
      toast.error("Audit Comment could not be saved", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  return (
    <div style={Container}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Grid container style={{ width: "75%", display: "flex" }}>
          <Grid container style={{ width: "80%" }}>
            <Grid
              item
              style={{ width: "30%" }}
              paddingRight={1}
            >
              <p
                style={{
                  fontSize: 13,
                  color: "#17191B",
                  marginBottom: "3px",
                }}
              >
                Certificate Ref
              </p>

              <Input
                style={{ ...filterInputStyleObj }}
                name="certificate_ref"
                onChange={handleFilterChange}
                value={searchFieldsParams.certificate_ref}
                placeholder="Type certificate ref"
                required
                disableUnderline={true}
              />
            </Grid>
            <Grid
              style={{ width: "35%" }}
              paddingLeft={1}
              paddingRight={1}
            >
              <p
                style={{
                  fontSize: 13,
                  color: "#17191B",
                  marginBottom: "3px",
                }}
              >
                BDX File Name
              </p>
              <Input
                style={{ ...filterInputStyleObj }}
                name="bdx_file_name"
                onChange={handleFilterChange}
                value={searchFieldsParams.bdx_file_name}
                placeholder="Type bdx file name"
                required
                disableUnderline={true}
              />
            </Grid>

            <Grid
              item
              style={{ width: "35%" }}
              paddingRight={1}
            >
              <p
                style={{
                  fontSize: 13,
                  color: "#17191B",
                  marginBottom: "3px",
                }}
              >
                Overall Status
              </p>
              <Select
                style={{ ...selectStyleObj }}
                name="overall_status"
                labelId=""
                id=""
                multiple
                placeholder="Select Overall Status"
                value={searchFieldsParams.overall_status}
                onChange={handleFilterChange}
                renderValue={(selected) => selected.join(",").substr(0, 30)} //value joined as an array
              >
                {overallStatuses.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      checked={
                        searchFieldsParams?.overall_status?.indexOf(name) > -1
                      }
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid style={{ display: "flex", width: "20%" }}>
            <Grid
              item
              ml={"15px"}
              display={"flex"}
              flexDirection={"column"}
              gap={"2px"}
            >
              <div
                style={{
                  height: "35px",
                }}
              ></div>

              <CommonButton
                text="Search"
                className="bankStatementBtn"
                handleClick={handleSearchPaymentUpdatesList}
                hoverColor="#EE3F00"
                disabled={pageState?.isLoading || isFilterBtnDisable()}
              />
            </Grid>
            <Grid
              item
              gap={"2px"}
              display={"flex"}
              flexDirection={"column"}
              marginLeft={2}
            >
              <div
                style={{
                  height: "35px",
                }}
              ></div>
              <CommonButton
                text="Clear"
                handleClick={handleClearSearchList}
                className="bankStatementBtn"
                hoverColor="#EE3F00"
                disabled={pageState?.isLoading || isFilterBtnDisable()}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          height: "calc(100vh - 262px)",
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          className={classes.pagination}
          loading={pageState?.isLoading}
          rows={pageState?.data ?? []}
          getRowId={(row) => row?.id}
          rowCount={pageState?.total}
          columns={columns}
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          pagination
          paginationMode="server"
          disableSelectionOnClick
          page={pageState.page - 1}
          pageSize={pageState?.pageSize ?? 25}
          onPageSizeChange={(newPageSize) => {
            return setPageState((old) => {
              setPaginationCount(getPaginationCount(pageState?.total, newPageSize))
              return { ...old, pageSize: newPageSize, page: 1 };
            });
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          sx={{
            "& .MuiDataGrid-columnHeader[data-field='File_Name']": {
              minWidth: toggle?.isOpen
                ? "170.137px !important"
                : "198px !important",
            },
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          components={{
            Toolbar: GridToolbarContainer,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Uploaded Files
              </Stack>
            ),
          }}
        />
        <Stack
          style={{
            position: "absolute",
            bottom: "8px",
          }}
        >
          <CommonPaginationContainer
            count={paginationCount + 1}
            pageState={pageState}
            setPageState={setPageState}
          />
        </Stack>
      </div>
      {selectedRowIndex != null && (
        <div className="">
          <ExceptionReportDetails record={pageState?.data[selectedRowIndex]} />
        </div>
      )}

      <Dialog open={openCommentsDialog} onClose={handleCloseCommentDialog}>
        <DialogTitle>{commentType === "comment" ? "Comment" : commentType === "comments_audit" ? "Comments - Audit" : ""}</DialogTitle>
        <DialogContent sx={{ overflow: "hidden", textAlign: "center" }}>
          <TextareaAutosize
            minRows={5}
            value={dialogCommentsValue}
            onChange={handleDialogInputChange}
            className=""
            style={{ width: "350px", minWidth: "350px" }}
          />
        </DialogContent>

        <DialogActions>
          <CommonButton
            text="Cancel"
            handleClick={handleCloseCommentDialog}
            sx={{
              backgroundColor: "#ffff",
              color: "#000000",
              border: "1px solid #BCBDBF",
            }}
          />
          <CommonButton
            text="Save"
            handleClick={handleSaveComments}
            hoverColor="#FF5A01"
            disabled={!dialogCommentsValue}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExceptionReport;
