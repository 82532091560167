import { FormLabel, Grid, TextField } from "@mui/material";


export const ViewEntity = ({
  row,
  viewMode,
}) => {
  //inline style
  const InlineStyle = {
    EntityDivison: {
      width: "230px", height: "32", padding: "0px"
    },
  }
  return (
    <form>
      <Grid container
       justifyContent={"space-between"}
       //  sx={{ width: "80%" }}
      >
        <Grid
          item
          xs={5}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>SLA (In Business Hours)</FormLabel>
          <TextField
            value={row.sla}
            sx={InlineStyle.EntityDivison}
            size="small"
            placeholder=""
            disabled={viewMode}
          />
          <br />
        </Grid>
        <Grid 
          item
          xs={5}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Created By </FormLabel>
          <TextField
            value={row.created_by}
            sx={InlineStyle.EntityDivison}
            size="small"
            placeholder="Enter Details Here"
            disabled={viewMode}
          />
          <br />
        </Grid>
        <Grid 
          item
          xs={5}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Updated By </FormLabel>
          <TextField
            value={row.updated_by}
            sx={InlineStyle.EntityDivison}
            size="small"
            placeholder=""
            disabled={viewMode}
          />
          <br />
        </Grid>
      </Grid>
    </form>
  );
}