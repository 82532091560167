import {
  FormLabel,
  Grid,
  TextField,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { AutoCompleteSelect } from "./AutoCompleteSelect";

export const ViewAndEditEntity = ({
  handleInputChange,
  row,
  viewMode,
  editMode,
  validationStatus,
  statuses,
  usersList,
  handleEscalationUserChange
}) => {
  //inline style
  const InlineStyle = {
    escalation: {
      position: "relative",
      width: "123px",
      height: "35",
      minWidth: "200px",
      maxHeight: "35px",
    },
  };
  return (
    <form>
      <Grid container justifyContent={"space-between"} sx={{ width: "80%" }}>
        <Grid
          item
          xs={5}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Transaction Type</FormLabel>
          {viewMode ? (
            <TextField
              value={row.transaction_type}
              onChange={(e) =>
                handleInputChange("transaction_type", e.target.value)
              }
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
            />
          ) : (
            <>
              {" "}
              <Select
                value={row.transaction_type}
                onChange={(e) =>
                  handleInputChange("transaction_type", e.target.value)
                }
                sx={InlineStyle.escalation}
                size="small"
                placeholder="Enter Details Here"
                disabled={viewMode}
              >
                <MenuItem
                  value={"Bank Transaction"}
                >
                  <ListItemText
                    primary={"Bank Transaction"}
                    className="list-item"
                  />
                </MenuItem>
                <MenuItem
                  value={"Cash Allocation"}
                >
                  <ListItemText
                    primary={"Cash Allocation"}
                    className="list-item"
                  />
                </MenuItem>
              </Select>
              <br />
              {validationStatus?.transaction_type && (
                <span style={{ color: "red" }}>
                  {validationStatus?.transaction_type}
                </span>
              )}
            </>
          )}
        </Grid>
        <Grid
          item
          xs={5}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Status</FormLabel>
          {viewMode ? (
            <TextField
              value={row.status}
              onChange={(e) => handleInputChange("status", e.target.value)}
              sx={InlineStyle.escalation}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
            />
          ) : (
            <>
              <Select
                value={row.status}
                onChange={(e) => handleInputChange("status", e.target.value)}
                sx={InlineStyle.escalation}
                size="small"
                placeholder="Enter Details Here"
                disabled={viewMode}
              >
                {(statuses||[]).map((status) => (
                  <MenuItem value={status} 
                  >
                    <ListItemText
                      primary={status}
                      className="list-item"
                    />
                  </MenuItem>
                ))}
              </Select>
              <br />
              {validationStatus?.status && (
                <span style={{ color: "red" }}>{validationStatus?.status}</span>
              )}
            </>
          )}
        </Grid>

        <Grid
          item
          xs={5}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Organization</FormLabel>
          {viewMode ? (
            <TextField
              value={row.organization}
              onChange={(e) =>
                handleInputChange("organization", e.target.value)
              }
              sx={InlineStyle.escalation}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
            />
          ) : (
            <>
              <Select
                value={row.organization}
                onChange={(e) =>
                  handleInputChange("organization", e.target.value)
                }
                sx={InlineStyle.escalation}
                size="small"
                placeholder="Enter Details Here"
                disabled={viewMode}
              >
                <MenuItem value={"Mosaic"} 
                >
                  <ListItemText
                    primary={"Mosaic"}
                    className="list-item"
                  />
                </MenuItem>
                <MenuItem value={"WNS"} 
                >
                  <ListItemText
                    primary={"WNS"}
                    className="list-item"
                  />
                </MenuItem>
              </Select>

              <br />
              {validationStatus?.organization && (
                <span style={{ color: "red" }}>
                  {validationStatus?.organization}
                </span>
              )}
            </>
          )}
        </Grid>

        <Grid
          item
          xs={5}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Escalation-1</FormLabel>
          {viewMode ? (
            <TextField
              value={row.escalation_level_one.user_name}
              onChange={(e) =>
                handleInputChange("escalation_level_one", e.target.value)
              }
              sx={InlineStyle.escalation}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
            />
          ) : (
            <>
              <AutoCompleteSelect
                name={"escalation_level_one"}
                placeholder="Select Escalation 1"
                value={row.escalation_level_one}
                handleChange={(event, value, reason) =>
                  handleEscalationUserChange(
                    "escalation_level_one",
                    value === "" ? "" : value,
                    reason
                  )
                }
                options={usersList || []}
              />
              <br />
              {validationStatus?.escalation_level_one && (
                <span style={{ color: "red" }}>
                  {validationStatus?.escalation_level_one}
                </span>
              )}
            </>
          )}
        </Grid>

        <Grid
          item
          xs={5}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Escalation-2</FormLabel>
          {viewMode ? (
            <TextField
              value={row.escalation_level_two.user_name}
              onChange={(e) =>
                handleInputChange("escalation_level_two", e.target.value)
              }
              sx={InlineStyle.escalation}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
            />
          ) : (
            <>
              <AutoCompleteSelect
                name={"escalation_level_two"}
                placeholder="Select Escalation 2"
                value={row.escalation_level_two}
                handleChange={(event, value, reason) =>
                  handleEscalationUserChange(
                    "escalation_level_two",
                    value === "" ? "" : value,
                    reason
                  )
                }
                options={usersList || []}
              />
              <br />
              {validationStatus?.escalation_level_two && (
                <span style={{ color: "red" }}>
                  {validationStatus?.escalation_level_two}
                </span>
              )}
            </>
          )}
        </Grid>

        <Grid
          item
          xs={5}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Escalation-3</FormLabel>
          {viewMode ? (
            <TextField
              value={row.escalation_level_three.user_name}
              onChange={(e) =>
                handleInputChange("escalation_level_three", e.target.value)
              }
              sx={InlineStyle.escalation}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
            />
          ) : (
            <>
              <AutoCompleteSelect
                name={"escalation_level_three"}
                placeholder="Select Escalation 3"
                value={row.escalation_level_three}
                handleChange={(event, value, reason) =>
                  handleEscalationUserChange(
                    "escalation_level_three",
                    value === "" ? "" : value,
                    reason
                  )
                }
                options={usersList || []}
              />
              <br />
              {validationStatus?.escalation_level_three && (
                <span style={{ color: "red" }}>
                  {validationStatus?.escalation_level_three}
                </span>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
