import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";


//inline styles
const InlineStyles = {
    TableHeader: {
        backgroundColor: '#262671',
        color: "white",
        position: 'sticky',
        top: 0,
        zIndex: 1,
        height: "50px",
        width:"200px"
    },
    TableCells: {
        color: 'black',
        fontSize: '12px',
        height: '44px',
        padding: '0px'
    },
    TableBodyRows: {
        border: '1px solid #ddd', marginTop: "10px", paddingTop: "15px", verticalAlign: "top"
    },
    TableBodyRowCells: {
        height: '20px', padding: '0px'
    },
    slaTableCell: {
        height: "20px", padding: "3px", width:"200px"
    },
    sla: {
        position: "relative", width: "200x", height: "32"
    },
    MoreVertIconStyle: {
        color: 'FF5A01',
        background: 'transparent',
        cursor: 'pointer',
        transform: 'rotate(90deg)'
    },

}
export const AddSLADetails = ({

    handleInputChange,
    row,
    viewMode,
    validationStatus,
}) => {
    console.log("sla row updated inner", validationStatus)
    return (
        <Table>
            <TableHead
                style={InlineStyles.TableHeader}>
                <TableRow style={{ backgroundColor: "#FFC000" }}>
                    <TableCell className='TableCell'>SLA (In Business Hours)</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                    <TableRow 
                    style={InlineStyles.TableBodyRows}>
                        <TableCell sx={InlineStyles.slaTableCell}>
                            <TextField
                                value={row.sla}
                                onChange={(e) => handleInputChange( 'sla', e.target.value)}
                                sx={InlineStyles.sla}
                                size="small"
                                placeholder='Enter Details Here'
                                disabled={viewMode}
                            /><br />
                            {validationStatus?.sla && (
                                <span style={{ color: 'red' }}>{validationStatus?.sla}</span>
                            )}
                        </TableCell>
                    </TableRow>
                {/* ))} */}
            </TableBody>
        </Table>
    )
}