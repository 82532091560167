import dayjs from "dayjs";

export const listToMap = (list, key = "id", value = "") => {
  const map = {};
  list.forEach((l) => {
    map[l[key]] = value ? l[value] : l;
  });

  return map;
};

export const parseDate = (date) => {
  if (!(date instanceof Date) || isNaN(date.getTime())) return "";
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = `${date.getDate()}`.padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const startOfDay = (date) => {
  date.setHours(0, 0, 0, 0);
  return date;
};

export const parseAllocationDate = (date) => {
  if (typeof date !== "string" || date.length !== 17) return "";
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6, 8);
  return `${year}-${month}-${day}`;
};

export const parseAllocationMonth = (date) => {
  date = `${date}`;

  if (date.length !== 5 && date.length !== 6) return "";

  if (date.length === 5) {
    const year = date.slice(1, 6);
    const month = date.slice(0, 1);

    return `${year}-0${month}`;
  } else {
    const year = date.slice(2, 7);
    const month = date.slice(0, 2);

    return `${year}-${month}`;
  }
};

export const doUiCalculations = (changedKey, index, rows) => {
  switch (changedKey) {
    case "receivable_amt":
    case "allocated_amt": {
      const row = rows[index];
      row.unallocated_amt = row.receivable_amt - row.allocated_amt;
      return rows;
    }
    default:
      return rows;
  }
};

export const doUiValidations = (row, validations) => {
  if (!row.bank_roe) validations.bank_roe = "Bank Roe is mandatory.";
  else if (row.bank_roe) {
    delete validations.bank_roe;
  }

  const allocatedAmount = +row?.allocated_amt;
  const receivableAmount = +row?.receivable_amt;

  if (allocatedAmount > 0 && receivableAmount > 0) {
    if (allocatedAmount > receivableAmount) {
      validations.allocated_amt =
        "Allocated amount must not be greater than receivable amount.";
    } else {
      delete validations?.allocated_amt;
    }
  } else if (validations.allocated_amt) {
    delete validations.allocated_amt;
  }
  return validations;
};

export const customDateFormat = (date) => {
  return (
    dayjs(date, 'DD-MM-YYYY').format('DD-MM-YYYY')
  )
}

export const getPaginationCount = (totalRecords, pageSize) => {
  if (totalRecords > pageSize) {
      const pageCount = totalRecords / pageSize;
      const parsedPageCount = Math.floor(pageCount);
      return parsedPageCount === pageCount ? parsedPageCount - 1 : parsedPageCount;
  } else {
      return 0;
  }
}