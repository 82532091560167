import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ParticipatingInsurer } from "../../Services/api";
import instance from "../../redux/api";
import { CommonButton } from "../common/commonButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TableContainer,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AddParticipatingInsurerDetails } from "./AddParticipatingInsurer";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ViewAndEditEntity } from "./ViewAndEditEntity";
import format from "date-fns/format";

// #region Styles
const AssignBankStatementBar = {
  display: "flex",
  justifyContent: "space-between",
  height: 32,
  fontWeight: "400",
  marginTop: 10,
};

const editEntityUpdate = {
  width: "90px",
  padding: "18px 0px",
  borderRadius: "20px",
  gap: "8px",
};

const bankTransactionAddBtn = {
  background: "#FF5A01",
  marginLeft: "5%",
  color: "white",
  borderRadius: "10px",
  padding: "5px 20px",
};

const ResetSubmit = {
  justifyContent: "flex-end",
  paddingTop: "0px",
  marginRight: "12px",
};

const addBankTransactionReset = {
  backgroundColor: "white",
  width: "90px",
  padding: "16px 0px",
  borderRadius: "20px",
  gap: "8px",
  color: "black",
  border: "1px solid black",
};

const EditPageContainerTransactions = {
  padding: "10px",
  cursor: "default",
  width: "100%",
  marginLeft: "-10px",
};

const CloseIconStyle = {
  backgroundColor: "#3B3F44",
  color: "#ffff",
  height: "16px",
  width: "16px",
  padding: "7px 8px",
  borderRadius: "32px",
};

const addBankTransactionSubmit = {
  backgroundColor: "#FF5A01",
  width: "90px",
  padding: "17px 0px",
  borderRadius: "13px",
  gap: "8px",
  color: "white",
};
// #endregion

export default function ParticipatingInsurerContainer() {
  // Selectors
  const toggle = useSelector((state) => state.toggleSideMenu);
  const { userData } = useSelector((state) => state?.user);
  const [row, setRow] = useState([{ participating_insurer: "" }]);
  const [tableRows, setTableRows] = useState([]);
  const [isApiCallInprogress, setIsApiCallInprogress] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [PartcipatingInsurerDialog, setPartcipatingInsurerDialog] =
    useState(false);
  const [editRow, setEditRow] = useState("");
  const [viewMode, setViewMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isCreated, setIsCreated] = useState(true);
  const [editId, setEditId] = useState("");
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [currentUpdatedFiledsInEditMode, setCurrentUpdatedFiledsInEditMode] =
    useState({});

  const [validationStatus, setValidationStatus] = useState(true);
  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  const handleDialogClose = () => {
    setPartcipatingInsurerDialog(false);
    setEditMode(false);
    setViewMode(false);
    
    if (!editMode && !viewMode) {
      setRow(
        {
          participating_insurer: "",
        },
      );
      setValidationStatus(true);
    }
    else if(editMode){
      setValidationStatus(true);
      setCurrentUpdatedFiledsInEditMode({});
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    let filteredData;
    if (value.trim() === "") {
      loadData();
      filteredData = tableRows;
    } else {
      filteredData = tableRows.filter((row) =>
        columns.some((column) => {
          const cellValue = row[column.field];
          if (Array.isArray(cellValue)) {
            // Check if the array is not null
            if (cellValue !== null && cellValue !== undefined) {
              // Check if any property within the objects in the array includes the search value
              return cellValue.some((item) =>
                Object.values(item).some(
                  (propValue) =>
                    propValue !== undefined &&
                    propValue !== null &&
                    propValue.toString().includes(value)
                )
              );
            }
          } else if (cellValue !== undefined && cellValue !== null) {
            // For non-array fields, continue with the existing logic
            return cellValue.toString().includes(value);
          }
          return false;
        })
      );
    }
    setTableRows(filteredData);
  };

  const handleActionButton = (rowData, isEditMode) => {
    const emptyRow = {
      participating_insurer: "",
    };

    if (rowData && isEditMode) {
      // Pre-fill the empty row with data for editing
      setRow({...rowData});
      setEditRow(rowData);
      setEditMode(true);
      setEditId(rowData.id);
      setIsCreated(false);
    } else if (rowData && !isEditMode) {
      setRow(rowData);
      setViewMode(true);
    } else {
      // If no rowDataForEdit is provided, it means we are in add mode
      setRow(emptyRow);
      setIsCreated(true);
      setPartcipatingInsurerDialog(true);
    }
    setPartcipatingInsurerDialog(true);
    if (rowData) {
      setIsCreated(false);
    } else {
      setIsCreated(true);
    }
  };

  const handleReset = () => {
    const emptyRow = {
      participating_insurer: "",
    };
    setRow(emptyRow);
    setValidationStatus(true);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.3, headerAlign: "center" },
    {
      field: "participating_insurer",
      headerName: "Participating Insurer",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 1,
      headerAlign: "center",
      valueGetter: (params) => params?.value ?? "-",
    },
    {
      field: "updated_by",
      headerName: "Last Modified By",
      flex: 1,
      headerAlign: "center",
      valueGetter: (params) => params?.value ?? "-",
    },
    {
      field: "updatedDateAndTime",
      headerName: "Last Modified Date",
      flex: 1,
      headerAlign: "center",
      valueGetter: (params) => format(params?.value, "dd-MM-yyyy") ?? "-",
    },
    ...(userData?.role !== "CC Processor"
      ? [
          {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            flex: 0.8,
            cellClassName: "actions",
            getActions: ({ row }) => {
              let actionsList = [];
              if (UserAuthorisation?.["Edit"] === "Y") {
                actionsList.push(
                  <GridActionsCellItem
                    icon={
                      <Tooltip title="Edit">
                        {" "}
                        <EditIcon />{" "}
                      </Tooltip>
                    }
                    label="Edit"
                    className="textPrimary"
                    onClick={() => handleActionButton(row, true)}
                    color="inherit"
                  />
                );
              }
              // if (UserAuthorisation?.['Delete'] === "Y") {
              //   actionsList.push(
              //     <GridActionsCellItem
              //       icon={<Tooltip title="Delete"><DeleteIcon /> </Tooltip>}
              //       label="Delete"
              //       onClick={() => handleDeleteClick(row.id)}
              //       color="inherit"
              //     />
              //   );
              // }
              if (UserAuthorisation?.["View"] === "Y") {
                actionsList.push(
                  <GridActionsCellItem
                    icon={<VisibilityIcon />}
                    label="view"
                    className="textPrimary"
                    onClick={() => handleActionButton(row, false)}
                    color="inherit"
                  />
                );
              }
              return actionsList;
            },
          },
        ]
      : []),
  ];

  const loadData = async () => {
    try {
      setIsApiCallInprogress(true);
      const resp = await instance.get(ParticipatingInsurer);
      setIsApiCallInprogress(false);
      if (resp.status === 200) {
        // const sortedData = resp.data.sort((a, b) => b.id - a.id);
        setTableRows(resp.data);
      } else {
        setTableRows([]);
      }
    } catch (err) {
      setIsApiCallInprogress(false);
      console.error("err", err);
    }
  };

  const UserAuthorisation =
    userData?.user_permissions?.permissions_list["Admin Table Maintenance"];

  useEffect(() => {
    loadData();
  }, []);

  const handleInputChange = (colName, value) => {
    const updatedRow = { ...row };

    updatedRow[colName] = value;
    setRow(updatedRow);
    // Edit Mode
    if (editMode) {
      setCurrentUpdatedFiledsInEditMode({
        ...currentUpdatedFiledsInEditMode,
        [colName]: value,
      });
    }
  };
  const validateRow = (row) => {
    const fieldValidations = {};

    // Perform your validations here, for example:
    if (
      !row ||
      !row.participating_insurer ||
      row.participating_insurer.trim() === ""
    ) {
      fieldValidations.participating_insurer = "This field is required";
    }
    // Add more validations for other fields...

    setValidationStatus((prevStatus) => {
      const newStatus = fieldValidations;
      return newStatus;
    });

    return Object.keys(fieldValidations).length === 0;
  };

  const handleSubmit = async () => {
    // Validate row before submission
    const isRowValid = validateRow(row);

    let editModeObj = {};
    if (editMode) {
      editModeObj.id = editRow?.id;
      editModeObj["updated_by"] = userData?.user_name ?? "";
      editModeObj = { ...editModeObj, ...currentUpdatedFiledsInEditMode };
    }

    if (!isRowValid) {
      // If row is invalid, stop the submission and display an error
      toast.error("Please fill all required fields.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    try {
      let response;
      if (editMode) {
        response = await instance.patch(
          ParticipatingInsurer + editId + "/",
          editModeObj
        );
        // clear preiously updated stored data
        setCurrentUpdatedFiledsInEditMode({});
      } else {
        row.created_by = userData?.user_name ?? "";

        response = await instance.post(ParticipatingInsurer, row);
      }

      if (response) {
        if (editMode) {
          toast.success("Updated successfully !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.success("Submission successful !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }

        // clean up
        cleanupTransactionData();
      }
    } catch (err) {
      toast.error("Submission failed. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const cleanupTransactionData = () => {
    handleDialogClose();
    loadData();
  };

  return (
    <div style={Container}>
      <div style={AssignBankStatementBar}>
        <span style={{ color: "#FF5A01", fontSize: "24px" }}>
          Participating Insurers
        </span>
        <div style={{ display: "flex", marginRight: "10px" }}>
          {UserAuthorisation?.["Creation"] === "Y" && (
            <>
              <CommonButton
                text="Add"
                handleClick={() => handleActionButton()}
                sx={bankTransactionAddBtn}
                hoverColor="#FF5A01"
              />
            </>
          )}
        </div>
      </div>

      <div
        style={{
          height: "calc(100vh - 230px)",
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          loading={isApiCallInprogress}
          rows={tableRows}
          columns={columns}
          // pageSize={pageSize}
          rowCount={tableRows?.length}
          hideFooterPagination
          hideFooter
          // onPageChange={(newPage) => {
          //   setPage(newPage);
          // }}
          // paginationMode="server"
          // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          showCellRightBorder={true}
          rowHeight={38}
          // checkboxSelection
          // onSelectionModelChange={(id) => {
          //   setArrToBeDeleted(id);
          // }}
        />
      </div>
      {/* <ParticipatingPartnerDetails /> */}
      <Dialog
        open={PartcipatingInsurerDialog}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        // PaperProps={{ style: dialogStyle }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ cursor: "default", paddingBottom: "0px" }}
        >
          {viewMode
            ? "View Participating Insurer"
            : editMode
            ? "Edit Participating Insurer"
            : "Add Participating Insurer"}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            style={{ position: "absolute", right: 12, top: 12 }}
          >
            <CloseIcon style={CloseIconStyle} />
          </IconButton>
          <hr style={{ width: "100%" }} />
        </DialogTitle>
        <DialogContent
          sx={{ overflowX: "hidden", padding: "0px 24px 5px 24px" }}
        >
          <div elevation={4} style={EditPageContainerTransactions}>
            {isCreated ? (
              <TableContainer style={{ maxHeight: "300px" }}>
                <AddParticipatingInsurerDetails
                  handleInputChange={handleInputChange}
                  row={row}
                  validationStatus={validationStatus}
                />
              </TableContainer>
            ) : (
              <>
                <Grid display={"flex"} flexDirection={"column"}>
                  <ViewAndEditEntity
                    row={row}
                    viewMode={viewMode}
                    validationStatus={validationStatus}
                    editMode={editMode}
                    handleInputChange={handleInputChange}
                  />
                </Grid>
              </>
            )}
          </div>
        </DialogContent>

        <DialogActions style={ResetSubmit}>
          {viewMode ? null : (
            <>
              {isCreated && (
                <CommonButton
                  sx={addBankTransactionReset}
                  text="Reset"
                  handleClick={() => handleReset()}
                  disabled={editMode}
                />
              )}
              {editMode && (
                <CommonButton
                  sx={editEntityUpdate}
                  text="Update"
                  handleClick={handleSubmit}
                  disabled={
                    Object.keys(currentUpdatedFiledsInEditMode)?.length <= 0
                  }
                  hoverColor="#FF5A01"
                />
              )}
              {!editMode && !viewMode && (
                <CommonButton
                  sx={addBankTransactionSubmit}
                  text="Submit"
                  handleClick={handleSubmit}
                  hoverColor="#FF5A01"
                />
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
