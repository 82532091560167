import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive"
import { doughnutColors } from "./ChartColors";


const EmployeeStatusBarChart = ({ data }) => {
    let chartData = [];

    const commonObj = {
        "Allocated": 0,
        "Query - Binder": 0,
        "Query - Correction": 0,
        "Query - Broker": 0,
        "Query - UWR": 0,
        "In Progress - CC": 0,
        "Query - F&A": 0,
        "Query - GXB": 0,
        "In Progress - GXB Transfer": 0,
        "Query - Bind": 0,
        "Open": 0,
    };

    for (let i = 0; i < data?.length; i++) {
        const { user_name, allocation_status, status_count } = data[i];
    
        if (chartData?.length === 0) {
            let objInfo = {
                user_name: user_name,
                ...commonObj,
            }
            objInfo[allocation_status] = status_count
            chartData.push(objInfo);
        } else {
            const isUserExist = chartData.some(item => item.user_name === user_name);
            if (isUserExist) {
                const getIndex = chartData.findIndex(item => item.user_name === user_name);
                if (getIndex !== -1) {
                    chartData[getIndex][allocation_status] = (chartData[getIndex][allocation_status] || 0) + status_count;
                }
            } else {
                let objInfo = {
                    user_name: user_name,
                    ...commonObj,
                }
                objInfo[allocation_status] = status_count
                chartData.push(objInfo);
            }
        }
    }

    chartData = chartData.filter(item => {
        return Object.keys(commonObj).some(key => item[key] !== 0);
    });

    useEffect(() => {
        const root = am5.Root.new("employeeStatusBarChart");
        const filterChartData = chartData.filter((item) => item?.status !== 0)
        disPlayEmployeeStatusBarChart(root, filterChartData)

        return () => {
            root.dispose()
        }
    })

    const disPlayEmployeeStatusBarChart = (root, chartData) => {

        root._logo.dispose()
        const responsive = am5themes_Responsive.new(root);

        responsive.addRule({
            name: 'XYChart',
            relevant: function (width) {
                return width < 1000;
            },
            settings: {
                layout: root.horizontalLayout
            }
        });
        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root),
            responsive,
        ]);

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            paddingLeft: 0,
            layout: root.verticalLayout
        }));

        chart.zoomOutButton.set('forceHidden', true)

        // Add legend
        // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
        let legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50,
                marginTop: 20,
            })
        );

        legend.labels.template.setAll({
            fill: doughnutColors.Mosaic_textBlue,
            fontSize: 10,
            fontFamily: '$base-font-family',
        });

        legend.markers.template.setAll({
            width: 12,
            height: 12,
        });

        legend.markerRectangles.template.setAll({
            cornerRadiusTL: 10,
            cornerRadiusTR: 10,
            cornerRadiusBL: 10,
            cornerRadiusBR: 10
        });

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        let xRenderer = am5xy.AxisRendererX.new(root, {
            minGridDistance: 35,
            minorGridEnabled: true
        })

        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "user_name",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
        }));

        xRenderer.labels.template.setAll({
            rotation: -45, // Rotate labels vertically
            centerY: am5.p50, // Adjust alignment to be in the middle vertically
            centerX: am5.p100, // Adjust alignment to be at the end horizontally
            paddingRight: 10, // Add some padding to avoid cutting off text
        });

        xRenderer.grid.template.setAll({
            location: 1
        })

        xAxis.data.setAll(chartData);

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            min: 0,
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0.1,
                minGridDistance: 30,
            }),
            minGridDistance: 35
        }));

        const yRenderer = yAxis.get("renderer")
        yRenderer.labels.template.setAll({
            fontSize: 12,
            fill: doughnutColors.Mosaic_textBlue,
            fontWeight: 500,
            fontFamily: 'sans-Serif'
        })

        xRenderer = xAxis.get("renderer")
        xRenderer.labels.template.setAll({
            fontSize: 12,
            fill: doughnutColors.Mosaic_textBlue,
            fontWeight: 500,
            fontFamily: 'sans-Serif'
        })

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function makeSeries(name, fieldName, colorCode) {
            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                stacked: true,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                categoryXField: "user_name",
                fill: colorCode,
            }));

            series.columns.template.setAll({
                tooltipText: "{name}, {categoryX}: {valueY}",
                width: am5.percent(10),
                tooltipY: 10,
            });

            let tooltip = am5.Tooltip.new(root, {
                autoTextColor: true,
                getFillFromSprite: true,
                getStrokeFromSprite: true,
                pointerOrientation: "left",
                dx: 20,
                dy: -8,
            })

            tooltip.get('background').setAll({
                stroke: 1
            })

            tooltip.label.setAll({
                fontSize: 12,
                fontFamily: 'san-Safir',
                fill: doughnutColors.Mosaic_White,

            })

            series.set("tooltip", tooltip)
            series.data.setAll(chartData);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear();

            legend.data.push(series);
        }

        makeSeries("Allocated", "Allocated", doughnutColors?.Mosaic_violet)
        makeSeries("Query - Correction", "Query - Correction", doughnutColors?.Mosaic_Blue)
        makeSeries("Query - Broker", "Query - Broker", doughnutColors?.Mosaic_gold)
        makeSeries("Query - UWR", "Query - UWR", doughnutColors?.Mosaic_gold_secondary)
        makeSeries("In Progress - CC", "In Progress - CC", doughnutColors?.Mosaic_Dark_Blue_Gray)
        makeSeries("Query - F&A", "Query - F&A", doughnutColors?.Mosaic_Dark_Gray)
        makeSeries("Query - GXB", "Query - GXB", doughnutColors?.Mosaic_Green_Primary)
        makeSeries("In Progress", "In Progress", doughnutColors?.Mosaic_Dark_Orange)
        makeSeries("Query - Bind", "Query - Bind", doughnutColors?.Mosaic_Green)
        makeSeries("Query - Binder", "Query - Binder", doughnutColors?.Mosaic_cyan)
        makeSeries("Open", "Open", doughnutColors?.Mosaic_dark_light_red)

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);
    }

    return (
        <div id="employeeStatusBarChart" style={{ height: "300px", marginTop: 10 }} />
    )
}

export default EmployeeStatusBarChart