import {
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";

export const ViewAndEditEntity = ({
  handleInputChange,
  row,
  viewMode,
  validationStatus

}) => {
  //inline style
  const InlineStyle = {
    ParticipatingInsurer: {
      width: "230px",
      height: "32",
      padding: "0px",
    }
  };
  return (
    <form>
      <Grid container justifyContent={"space-between"} sx={{ width: "80%" }}>
        <Grid
          item
          xs={5}
          display={"flex"}
          flexDirection={"column"}
          gap={"5px"}
          mt={"20px"}
        >
          <FormLabel>Participating Insurer</FormLabel>
          <TextField
            value={row.participating_insurer}
            onChange={(e) => handleInputChange("participating_insurer", e.target.value)}
            sx={InlineStyle.ParticipatingInsurer}
            size="small"
            placeholder="Enter Details Here"
            disabled={viewMode}
          />
          <br />
          {validationStatus?.participating_insurer && (
            <span style={{ color: "red" }}>
              {validationStatus?.participating_insurer}
            </span>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
