import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

//inline styles
const InlineStyles = {
  TableHeader: {
    backgroundColor: "#262671",
    color: "white",
    position: "sticky",
    top: 0,
    zIndex: 1,
    height: "50px",
  },
  TableCells: {
    color: "black",
    fontSize: "12px",
    height: "44px",
    padding: "0px",
    width:"300px"
  },
  TableBodyRows: {
    border: "1px solid #ddd",
    marginTop: "10px",
    paddingTop: "15px",
    verticalAlign: "top",
  },
  TableBodyRowCells: {
    height: "20px",
    padding: "0px"
  },
  insurerTableCell: {
    height: "30px",
    width:"200px",
    padding: "3px",
  },
  insurer: {
    position: "relative",
    width: "123px",
    height: "32",
    minWidth:"180px",
  },
  MoreVertIconStyle: {
    color: "FF5A01",
    background: "transparent",
    cursor: "pointer",
    transform: "rotate(90deg)",
  }
};
export const AddParticipatingInsurerDetails = ({
  handleInputChange,
  row,
  viewMode,
  validationStatus
}) => {
  return (
    <Table>
      <TableHead style={InlineStyles.TableHeader}>
        <TableRow style={{ backgroundColor: "#FFC000" }}>
          <TableCell className="TableCell" style={InlineStyles.TableCells}>Participating Insurer</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow style={InlineStyles.TableBodyRows}>
          <TableCell sx={InlineStyles.insurerTableCell}>
            <TextField
              value={row.participating_insurer}
              onChange={(e) =>
                handleInputChange("participating_insurer", e.target.value)
              }
              sx={InlineStyles.insurer}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
            />
            <br />
            {validationStatus?.participating_insurer && (
              <span style={{ color: "red" }}>
                {validationStatus?.participating_insurer}
              </span>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
