import React from "react";
import { Autocomplete, TextField} from "@mui/material";

export const AutoCompleteSelect = ({
  customStyles = {
    maxWidth: "auto",
    position: "relative",
    minWidth: "200px",
    maxHeight: "35px",
  },
  disabled,
  handleChange,
  name,
  options,
  size = "small",
  value = "",
}) => {
  return (
    <Autocomplete
      autoHighlight
      disabled={disabled}
      name={name}
      onChange={(event, value, reason) => handleChange(event, value, reason)}
      options={[...options]}
      sx={{...customStyles,
        "& .MuiInputBase-input.MuiOutlinedInput-input": {
          color: "#343434 !important",
        },
        "& .MuiAutocomplete-listbox .MuiAutocomplete-option:hover": {
          backgroundColor: "lightgray",
        },
      }}
      size={size}
      value={value}
      getOptionLabel={(option) => option?.user_name || ""}
      filterOptions={(options, state) =>
        options.filter((option) =>
          option.user_name
            .toLowerCase()
            .includes(state.inputValue.toLowerCase())
        )
      }
      renderOption={(props, option) => (
        <li {...props}>
          {option.user_name}
        </li>
        )}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      renderInput={(params) => <TextField {...params} label="" />}
    />
  );
};
