import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Stack, Grid, Input, FormLabel, TextField, Checkbox } from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { useStyles } from "./paymentUpdatesContainerStyles";
import { CommonPaginationContainer, CommonSelect } from "../common";
import { CommonButton } from "../common/commonButton";
import instance from "../../redux/api";
import { paymentTreasury, getAllPaymentIds, getPayoutSummary } from "../../Services/api";
import { getPaginationCount } from "../CashAllocation/utils";

const TreasuryList = () => {
  const { search } = useLocation();
  const policyNo = new URLSearchParams(search).get("policyNo");
  const fileName = new URLSearchParams(search).get("fileName");
  const paymentType = new URLSearchParams(search).get("paymentType");

  const [paginationCount, setPaginationCount] = useState(0);
  const [paymentIds, setPaymentIds] = useState([]);
  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [isClearPerformed, setIsClearPerformed] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState([]);

  const [searchFieldParams, setSearchFieldsParams] = useState({
    type_of_payment: paymentType,
    policy_no: policyNo,
    bdx_file_name: fileName,
    payment_id: "",
  });

  const toggle = useSelector((state) => state.toggleSideMenu);

  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
      ? pageState?.page
      : pageState?.page - 1;
  };

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  //styles
  const classes = useStyles();

  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  const paymentTypes = [
    "Syndicate Payment",
    "Internal Broking",
    "MGA Commission",
    "Rebate",
  ];

  const paymentStatus = [
    {label: "Paid", value: true},
    {label: "Not Paid", value: false}
  ];

  const getPaymentIds = async () => {
    const response = await instance.get(`${getAllPaymentIds}`);
    setPaymentIds(response?.data?.data);
  };

  const searchQueryParams = () => {
    let getsearchQueryParams = "";

    const { type_of_payment, policy_no, bdx_file_name, payment_id } =
      searchFieldParams;

    if (type_of_payment) {
      getsearchQueryParams = `&type_of_payment=${type_of_payment}`;
    }

    if (policy_no) {
      getsearchQueryParams = `${getsearchQueryParams}&certificate_ref=${policy_no}`;
    }

    if (bdx_file_name) {
      getsearchQueryParams = `${getsearchQueryParams}&file_name=${bdx_file_name}`;
    }

    if (payment_id) {
      getsearchQueryParams = `${getsearchQueryParams}&payment_id=${payment_id}`;
    }

    return getsearchQueryParams;
  };

  const loadSearchTxnData = async () => {
    try {
      let queryParams = `skip=0&pageSize=${pageState?.pageSize}`;

      const getSearchQueryParam = searchQueryParams();
      if (getSearchQueryParam) {
        queryParams = `${queryParams}${getSearchQueryParam}`;
      }

      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const response = await instance.get(`${paymentTreasury}?${queryParams}`);
      

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      setIsSearchPerformed(true);
      setIsClearPerformed(false);

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ? response?.data?.data : [],
        }));
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count ?? 0,
          data: [],
          page: 1, // required for sending skip as 0
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));
      console.log("err", err);
    }
  };

  const loadData = async () => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      let queryParams = `skip=${currentSkipNumber()}&pageSize=${
        pageState?.pageSize
      }`;

      if (isSearchPerformed) {
        const getSearchQueryParam = searchQueryParams();
        if (getSearchQueryParam) {
          queryParams = `${queryParams}${getSearchQueryParam}`;
        }
      } else {
        // If we get information while performing route navigation
        if (!isClearPerformed) {
          if (policyNo) {
            queryParams = `${queryParams}&certificate_ref=${policyNo}`;
            setSearchFieldsParams({
              ...searchFieldParams,
              policy_no: policyNo,
            });
          }

          if (fileName) {
            queryParams = `${queryParams}&file_name=${fileName}`;
            setSearchFieldsParams({
              ...searchFieldParams,
              bdx_file_name: fileName,
            });
          }

          if (paymentType) {
            queryParams = `${queryParams}&type_of_payment=${paymentType}`;
            setSearchFieldsParams({
              ...searchFieldParams,
              type_of_payment: paymentType,
            });
          }
        }
      }

      const response = await instance.get(`${paymentTreasury}?${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ?? [],
        }));
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.error("err", err);
    }
  };

  const handleSelectDropdown = (name, value, reason) => {
    if (reason === "clear") {
      loadData();
    }
    setSearchFieldsParams({
      ...searchFieldParams,
      [name]: value ?? "",
    });
  };

  const handleClearSearchList = async () => {
    setIsSearchPerformed(false);
    setIsClearPerformed(true);

    // clear fields
    setSearchFieldsParams({
      type_of_payment: "",
      policy_no: "",
      bdx_file_name: "",
      payment_id: "",
    });
  };

  const handleReleasePaymentClick = () => {
    const allData = pageState.data;
    let payload = [];
    selectedRecords.forEach(value => {
      const currentData = allData.find(data => data.id == value)
      payload.push({
        id: value,
        payment_status: currentData.payment_status,
        payment_date: currentData.payment_date,
        wire_no: currentData.wire_no
      })
    })
  }

  const handleSavePartnerPayment = async (currentClickedRow) => {
    try {
      const paymentObj = {
        payment_date: currentClickedRow?.payment_date,
        wire_no: currentClickedRow?.partnerWireNo,
        payment_status: currentClickedRow?.payment_status,
      };

      const response = await instance.patch(
        `${paymentTreasury}${currentClickedRow?.id}`,
        paymentObj
      );

      if (response) {
        toast.success(`Payment status saved successfully !`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error("Unable to Save Partner Payment", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to Save Partner Payment", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const updateTreasuryRowFields = (currentRow, fieldName, valueToUpdate) => {
    let currentPageData = pageState.data;

    const getCurrentRowId = currentRow?.id;
    const getIndex = currentPageData.findIndex(
      (item) => item?.id === getCurrentRowId
    );
    currentPageData[getIndex][fieldName] = valueToUpdate;

    setPageState({
      ...pageState,
      data: currentPageData,
    });
  };

  const handleRadioChange = (e) => {
    const { value, checked } = e.target
    
    if(checked)
      setSelectedRecords([...selectedRecords, Number(value)])

    else
      setSelectedRecords([...selectedRecords.filter(record => record !== Number(value))])
  };
  const syndicatePaymentColumns = [
    {
      field: "source_input",
      headerName: "Input Source",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "file_name",
      headerName: "File Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "certificate_ref",
      headerName: "Policy Number",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "coverholder_name",
      headerName: "Coverholder Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "coverholder_entity",
      headerName: "Coverholder Entity",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_id",
      headerName: "Payment ID",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "type_of_payment",
      headerName: "Payment Type",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "bank_account",
      headerName: "Bank Account",
      headerAlign: "center",
      width: 160,
    },    
    {
      field: "sett_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "net_transfer",
      headerName: "Net Transfer",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      headerAlign: "center",
      width: 160,
    },
  ];

  const commissionPaymentColumns = [
    {
      field: "file_name",
      headerName: "File Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "certificate_ref",
      headerName: "Policy Number",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "category",
      headerName: "Category",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "coverholder_name",
      headerName: "Coverholder Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "receiving_bank_account",
      headerName: "Bank Account",
      headerAlign: "center",
      width: 160,
    },    
    {
      field: "settlement_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
  ];

  const rebatePaymentColumns = [
    {
      field: "source_input",
      headerName: "Input Source",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "file_name",
      headerName: "File Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "certificate_ref",
      headerName: "Policy Number",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "coverholder_name",
      headerName: "Coverholder Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "coverholder_entity",
      headerName: "Coverholder Entity",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_id",
      headerName: "Payment ID",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "type_of_payment",
      headerName: "Payment Type",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "bank_account",
      headerName: "Bank Account",
      headerAlign: "center",
      width: 160,
    },    
    {
      field: "sett_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "net_transfer",
      headerName: "Net Transfer",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      headerAlign: "center",
      width: 160,
    },
  ];

  const partnerPaymentColumns = [
    {
      field: "source_input",
      headerName: "Input Source",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "file_name",
      headerName: "File Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "certificate_ref",
      headerName: "Policy Number",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "coverholder_name",
      headerName: "Coverholder Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "coverholder_entity",
      headerName: "Coverholder Entity",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_id",
      headerName: "Payment ID",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "type_of_payment",
      headerName: "Payment Type",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "bank_account",
      headerName: "Bank Account",
      headerAlign: "center",
      width: 160,
    },    
    {
      field: "sett_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "net_transfer",
      headerName: "Net Transfer",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      headerAlign: "center",
      width: 160,
    },
  ];
  

  const getColumnsByPaymentType = () => {
    let treasuryPaymentTypes = [];
    switch (searchFieldParams.type_of_payment) {
      case "Syndicate Payment":
        treasuryPaymentTypes = [...syndicatePaymentColumns];
        break;
      case "Internal Broking":
        treasuryPaymentTypes = [...partnerPaymentColumns];
        break;
      case "MGA Commission":
        treasuryPaymentTypes = [...commissionPaymentColumns];
        break;
      case "Rebate":
        treasuryPaymentTypes = [...rebatePaymentColumns];
        break;
      default:
        treasuryPaymentTypes = [...syndicatePaymentColumns];
        break;
    }
    return treasuryPaymentTypes;
  };

  const handleFieldsInputChange = (name, value, reason) => {
    if (!value) {
      handleClearSearchList();
    }
    setSearchFieldsParams({
      ...searchFieldParams,
      [name]: value?.trim(),
    });
  };

  useEffect(() => {
    setPaginationCount(getPaginationCount(pageState?.total, pageState?.pageSize));
  }, [pageState?.pageSize, pageState?.total]);

  // UseEffect
  useEffect(() => {
    if (isClearPerformed) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClearPerformed]);

  useEffect(() => {
    if (!isClearPerformed) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState?.pageSize, pageState?.page]);

  useEffect(() => {
    getPaymentIds();
  }, []);

  return (
    <div style={Container}>
      <span
        style={{
          color: "#FF5A01",
          fontSize: "20px",
          fontWeight: "400",
          marginTop: 5,
        }}
      >
        Treasury
      </span>

      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          mb: "5px",
        }}
        container
        xs={12}
        gap={"10px"}
      >
        <Grid item xs={12} md={2} paddingRight={1}>
          <FormLabel>Payment Type</FormLabel>
          <CommonSelect
            placeholder="Select Payment Type"
            value={searchFieldParams?.type_of_payment}
            handleChange={(event, value, reason) =>
              handleSelectDropdown("type_of_payment", value, reason, event)
            }
            options={paymentTypes?.map((option) => option) || []}
          />
        </Grid>

        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          gap={"2px"}
          xs={12}
          md={2}
        >
          <FormLabel>Policy No</FormLabel>
          <TextField
            value={searchFieldParams?.policy_no}
            onChange={(e) =>
              handleFieldsInputChange("policy_no", e.target.value)
            }
            size="small"
          />
        </Grid>

        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          gap={"2px"}
          xs={12}
          md={2}
        >
          <FormLabel>BDX File Name</FormLabel>
          <TextField
            value={searchFieldParams?.bdx_file_name}
            onChange={(e) =>
              handleFieldsInputChange("bdx_file_name", e.target.value)
            }
            size="small"
          />
        </Grid>

        <Grid item xs={12} md={2} paddingRight={1}>
          <FormLabel>Payment ID</FormLabel>
          <CommonSelect
            placeholder="Select Payment Id"
            value={searchFieldParams?.payment_id}
            handleChange={(event, value, reason) =>
              handleSelectDropdown("payment_id", value, reason, event)
            }
            options={paymentIds?.map((option) => option) || []}
          />
        </Grid>

        <Grid
          container
          xs={2.8}
          display={"flex"}
          flexDirection={"row"}
          gap={"6px"}
          mt={"20px"}
        >
          <Grid
            item
            ml={"15px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"2px"}
          >
            <FormLabel></FormLabel>
            <CommonButton
              text="Search"
              className="bankStatementBtn"
              handleClick={() => loadSearchTxnData()}
              hoverColor="#EE3F00"
              // disabled={pageState?.isLoading || isFilterBtnDisable()}
            />
          </Grid>
          <Grid
            item
            ml={"15px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"2px"}
          >
            <FormLabel></FormLabel>
            <CommonButton
              text="Clear"
              handleClick={handleClearSearchList}
              className="bankStatementBtn"
              hoverColor="#EE3F00"
              // disabled={pageState?.isLoading || isFilterBtnDisable()}
            />
          </Grid> 
        </Grid>
      </Grid>

      <div
        style={{
          height: "calc(100vh - 293px)",
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          className={classes.pagination}
          loading={pageState?.isLoading}
          rows={pageState?.data ?? []}
          rowCount={pageState?.total}
          getRowId={(row) => row?.id ?? ""}
          columns={getColumnsByPaymentType()}
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          pagination
          paginationMode="server"
          disableSelectionOnClick
          page={pageState.page - 1}
          pageSize={pageState?.pageSize ?? 25}
          onPageSizeChange={(newPageSize) => {
            return setPageState((old) => {
              setPaginationCount(Math.floor(pageState.total / newPageSize));
              return { ...old, pageSize: newPageSize, page: 1 };
            });
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          sx={{
            "& .MuiDataGrid-columnHeader[data-field='File_Name']": {
              minWidth: toggle?.isOpen
                ? "170.137px !important"
                : "198px !important",
            },
          }}
          components={{
            Toolbar: GridToolbarContainer,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Uploaded Files
              </Stack>
            ),
          }}
        />
        <Stack
          style={{
            position: "absolute",
            bottom: "8px",
          }}
        >
          <CommonPaginationContainer
            count={paginationCount + 1}
            pageState={pageState}
            setPageState={setPageState}
          />
        </Stack>
      </div>
    </div>
  );
};

export default TreasuryList;
