import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import format from "date-fns/format";
import instance from "../../redux/api";
import { CommonPaginationContainer, CommonSelect } from "../common";
import CustomDateRangePicker from "../common/CustomDateRangePicker";
import { CommonButton } from "../common/commonButton";
import {
  cashAllocationLockedUnlocked,
  getCashAllocationLockedUnlocked,
} from "../../Services/api";
import DialogBox from "../common/dialog";
import { useStyles } from "../bankTransactions/bankTranscationContainerStyles";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";

const InlineStyles = {
  MoreVertIconStyle: {
    color: "FF5A01",
    background: "transparent",
    cursor: "pointer",
    transform: "rotate(90deg)",
  },
  commentsField: {
    position: "relative",
    width: "123px",
    height: "32",
  },
  commentsDialog: {
    minWidth: "350px",
    width: "100%",
    padding: "5px",
    overflow: "hidden",
  },
};

export const LockUnlockContainer = () => {
  // Selectors
  const toggle = useSelector((state) => state.toggleSideMenu);
  const { userData } = useSelector((state) => state?.user);

  // Local state
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [unlockRecordCountTextMessage, setUnlockRecordCountTextMessage] =
    useState("");
  //For Date picker
  const [fromDateValue, setFromDateValue] = useState("");
  const [toDateValue, setToDateValue] = useState("");
  const [lockStateResponse, setLockStateResponse] = useState("");

  //For comments Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [currentClickedRow, setCurrentClickedRow] = useState("");
  const [dialogTextAreaChangeValue, setDialogTextAreaChangeValue] =
    useState("");

  //styles
  const classes = useStyles();
  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  //For pagination
  const [paginationCount, setPaginationCount] = useState(0);
  const [pageState, setPageState] = useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  // skip 0 means page 1
  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
      ? pageState?.page
      : pageState?.page - 1;
  };

  useEffect(() => {
    if (pageState?.total >= 5) {
      setPaginationCount(Math.floor(pageState.total / pageState?.pageSize));
    } else setPaginationCount(0);
  }, [pageState?.total]);

  useEffect(() => {
    if (userData?.id) {
      getLockedUnlockedCashAllocationRecords();
    }
  }, [userData, pageState?.pageSize, pageState?.page]);

  const getLockedUnlockedCashAllocationRecords = async () => {
    try {
      // set loading indicator
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const response = await instance.get(
        `${getCashAllocationLockedUnlocked}?skip=${currentSkipNumber()}&pageSize=${
          pageState?.pageSize
        }&user_id=${userData?.id}`
      );

      // set loading indicator
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response?.data) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.results ?? [],
        }));
        setPaginationCount(
          Math.floor(response?.data?.count / pageState?.pageSize)
        );
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.error("err", err);
    }
  };

  const isFilterBtnDisable = () => {
    let disabled = false;
    if (fromDateValue === "" || toDateValue === "") {
      disabled = true;
    }
    return disabled;
  };

  const handleCommentsCloseDialog = () => {
    setOpenDialog(false);
    setDialogTextAreaChangeValue("");
  };

  const handleDialogInputChange = (value) => {
    setDialogTextAreaChangeValue(value);
  };

  const handleSaveComments = async () => {
    const unLockModeObj = {
      user_id: userData?.id,
      id: currentClickedRow?.id,
      comment: dialogTextAreaChangeValue || "",
    };
    try {
      const response = await instance.patch(
        getCashAllocationLockedUnlocked,
        unLockModeObj
      );
      if (response) {
        getLockedUnlockedCashAllocationRecords();
        toast.success(`UnLocked successfully !`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setUnlockRecordCountTextMessage(response?.data?.message);
        setOpenDialog(false);
        setDialogTextAreaChangeValue("");
      } else {
        toast.error("Unable to UnLock Allocations", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to UnLock Allocations", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleActionLockDialog = async () => {
    const lockModeObj = {
      user_id: userData?.id,
      allocation_datetime: `${format(fromDateValue, "yyyy-MM-dd")}_${format(
        toDateValue,
        "yyyy-MM-dd"
      )}`,
      locked: true,
    };
    try {
      const response = await instance.patch(
        cashAllocationLockedUnlocked,
        lockModeObj
      );
      setLockStateResponse(response?.data?.message);
      if (response) {
        getLockedUnlockedCashAllocationRecords();

        toast.success(`Locked successfully !`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setDialogOpen(false);
        setFromDateValue("");
        setToDateValue("");
      } else {
        toast.error("Unable to Lock Allocations", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to Lock Allocations", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleUnlockRecord = async (params) => {
    setOpenDialog(true);
    setCurrentClickedRow(params);
  };

  const handleSubmitButton = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const clearData = () => {
    setFromDateValue("");
    setToDateValue("");
  };

  const handleCancelButton = () => {
    clearData();
  };

  const columns = [
    {
      field: "date_range",
      headerName: "Date Range",
      headerAlign: "center",
      flex: 0.6,
      renderCell: (params) => {
        const getDate = params?.row?.date_range.split("_");
        const startDate = getDate[0];
        const endDate = getDate[1];
        return (
          <span>{`${format(startDate, "dd-MM-yyyy")} - ${format(
            endDate,
            "dd-MM-yyyy"
          )}`}</span>
        );
      },
    },
    {
      field: "locked",
      headerName: "Lock / Unlock",
      headerAlign: "center",
      flex: 0.5,
      renderCell: (params) => {
        return <span>{params?.row?.locked ? "Locked" : "Unlocked"}</span>;
      },
    },
    {
      field: "no_of_record",
      headerName: "Count of Txn",
      headerAlign: "center",
      flex: 0.5,
      renderCell: (params) => <span>{params?.row?.no_of_record}</span>,
    },
    {
      field: "locked_unlocked_by_username",
      headerName: "Initiate by",
      headerAlign: "center",
      flex: 0.5,
    },
    {
      field: "locked_unlocked_date",
      headerName: "Locked / Unlocked Date",
      headerAlign: "center",
      flex: 0.6,
      renderCell: (params) => {
        return format(
          new Date(params?.row?.locked_unlocked_date),
          "dd-MM-yyyy"
        );
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      headerAlign: "center",
      flex: 0.5,
      renderCell: (params) => <span>{params?.row?.comment}</span>,
    },
    ...(userData?.role !== "CC Processor" ? [{
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <CommonButton
            text="Unlock"
            handleClick={() => handleUnlockRecord(params)}
            hoverColor="#EE3F00"
            disabled={params?.row?.comment}
          />
        );
      },
    }] : []),
  ];

  return (
    <Box style={Container}>
      <Box style={{ marginTop: "10px" }}>
        <span style={{ color: "#FF5A01", fontSize: "24px" }}>
          Locking Period
        </span>
      </Box>
      <Grid
        container
        display={"flex"}
        flexDirection={"row"}
        xs={12}
        gap={"10px"}
      >
        <Grid
          item
          gap={"15px"}
          alignItems={"center"}
          display={"flex"}
          height={"33px"}
        >
          <FormLabel>Accounting Month Range</FormLabel>
          <CustomDateRangePicker
            startDate={fromDateValue}
            setStartDate={setFromDateValue}
            endDate={toDateValue}
            setEndDate={setToDateValue}
            maxDate={new Date()}
          />
        </Grid>
        <Grid
          item
          display={"flex"}
          flexDirection={"row"}
          gap={"20px"}
          alignItems={"center"}
        >
          <FormLabel></FormLabel>
          <CommonButton
            text="Lock"
            handleClick={() => handleSubmitButton()}
            hoverColor="#EE3F00"
            disabled={isFilterBtnDisable()}
          />
          <CommonButton
            text="Cancel"
            handleClick={() => handleCancelButton()}
            style={{
              backgroundColor: "#f3f3f3",
              color: isFilterBtnDisable() ? "rgba(0, 0, 0, 0.26)" : "black",
            }}
            disabled={isFilterBtnDisable()}
          />
        </Grid>
      </Grid>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "5px",
        }}
      >
        <Typography
          variant="h3"
          style={{ fontWeight: "600", fontSize: 18, color: "rgb(255, 90, 1)" }}
        >
          {lockStateResponse}
        </Typography>

        <Typography
          variant="h3"
          style={{ fontWeight: "600", fontSize: 18, color: "rgb(255, 90, 1)" }}
        >
          {unlockRecordCountTextMessage}
        </Typography>
      </Box>

      <DialogBox
        title="Confirmation"
        body={
          <FormControl style={{ width: "100%" }}>
            <Typography>
              Are you sure that you want to Lock the processing for the
              "Allocated" transactions
            </Typography>
          </FormControl>
        }
        confirmText="Yes"
        cancelText="No"
        isOpen={isDialogOpen}
        onCancel={handleCloseDialog}
        onConfirm={handleActionLockDialog}
      />

      <div
        style={{
          height: "calc(100vh - 293px)",
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
          marginTop: "5px",
        }}
      >
        <DataGrid
          className={classes.pagination}
          loading={pageState?.isLoading}
          rows={pageState?.data ?? []}
          columns={columns}
          rowCount={pageState?.total}
          keepNonExistentRowsSelected
          disableSelectionOnClick
          pagination
          paginationMode="server"
          getRowId={(row) => row?.id}
          page={pageState.page - 1}
          pageSize={pageState?.pageSize ?? 25}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) => {
            return setPageState((old) => {
              setPaginationCount(Math.floor(pageState?.total / newPageSize));
              return { ...old, pageSize: newPageSize, page: 1 };
            });
          }}
          sx={{
            "& .MuiDataGrid-columnHeader[data-field='actions']": {
              minWidth: toggle?.isOpen
                ? "175.137px !important"
                : "203px !important",
            },
          }}
          components={{
            Toolbar: GridToolbarContainer, // Custom toolbar for filter reset button
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No CashAllocation Records
              </Stack>
            ),
          }}
        />
        <Stack
          style={{
            position: "absolute",
            bottom: "8px",
          }}
        >
          <CommonPaginationContainer
            count={paginationCount + 1}
            pageState={pageState}
            setPageState={setPageState}
          />
        </Stack>
      </div>
      <Dialog open={openDialog} onClose={handleCommentsCloseDialog}>
        <DialogTitle>Comments</DialogTitle>
        <DialogContent sx={{ overflow: "hidden" }}>
          <TextareaAutosize
            minRows={5}
            onChange={(e) => handleDialogInputChange(e.target.value)}
            className="PaymentReferenceDialogue"
          />
        </DialogContent>
        <DialogActions>
          <CommonButton
            text="Cancel"
            handleClick={handleCommentsCloseDialog}
            sx={{
              backgroundColor: "#ffff",
              color: "#000000",
              border: "1px solid #BCBDBF",
            }}
          />
          <CommonButton
            text="Submit"
            handleClick={handleSaveComments}
            hoverColor="#FF5A01"
            disabled={dialogTextAreaChangeValue === ""}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};
