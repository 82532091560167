import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { GridToolbar, DataGrid } from "@mui/x-data-grid";
import { userManagementGet } from "../../Services/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  Stack,
  Grid,
  FormLabel,
  Tab,
  Tabs,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import { Card } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "react-toastify/dist/ReactToastify.css";
import format from "date-fns/format";
import {
  BankList,
  BankTransaction,
  BrokerList,
  CurrencyList,
  GetDetailsByBank,
  GetUsers,
  MutlipleAssignTxnUser,
  UserAssignTxn,
} from "../../Services/api";
import { BANK_STATEMENT_ROW_DATA } from "../../utils/config";
import instance from "../../redux/api";
import DialogBox from "../common/dialog";
import { CommonButton } from "../common/commonButton";
import { ViewAndEditBankTransaction } from "../bankTransactions/ViewAndEditTxnDialog";
import {
  getAccountsList,
  getBrokerBranchesList,
  getCurrencyList,
} from "../../redux/slice/bankStatmentTransactionsSlice";
import { CommonPaginationContainer, CommonSelect } from "../common";
import { useStyles } from "../bankTransactions/bankTranscationContainerStyles";
import "./documents.css";
import { numberFormatter } from "../../utils/generalFunctions";
import { allocationStatusList } from "../CashAllocation/constants";
import { getPaginationCount } from "../CashAllocation/utils";


const allocateBankAddReset = {
  backgroundColor: "white",
  height: "40px",
  width: "90px",
  padding: "8px, 16px, 8px, 16px",
  borderRadius: "20px",
  gap: "8px",
  color: "black",
};

const allocateBankAddSubmit = {
  backgroundColor: "#FF5A01",
  height: "40px",
  width: "90px",
  padding: "8px, 16px, 8px, 16px",
  borderRadius: "20px",
  gap: "8px",
  color: "white",
};

const assignToCancel = {
  backgroundColor: "white",
  color: "black",
  border: "1px solid black",
  borderRadius: "20px",
  width: "90px",
};

const assignTo = {
  backgroundColor: "#FF5A01",
  borderRadius: "20px",
  color: "white",
  width: "90px",
};

const allocateBankTransactionAdd = {
  background: "#FF5A01",
  marginLeft: "5%",
  color: "white",
  borderRadius: "20px",
};

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",

  background: "#0E0E23",
}));

export default function DataTableAllocation() {
  // const { userData } = useSelector((state) => state?.user);
  const [selectedOption, setSelectedOption] = useState("");
  const [uploadOPen, setUploadOpen] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [sampleData, setSampleData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [bankPermissions, setBankPermissions] = useState("");
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const toggle = useSelector((state) => state.toggleSideMenu);
  const [searchFieldsPrams, setSearchFieldsPrams] = useState({
    Bank_Name: "",
    Bank_Transaction_Id: "",
    Bank_Allocation_Status: "",
  });

  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [isClearPerformed, setIsClearPerformed] = useState(false);
  const [isAllocationStatusDeSelected, setIsAllocationStatusDeSelected] = useState(false);

  //Pagination
  const [pageState, setPageState] = useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });
  const [paginationCount, setPaginationCount] = useState(0);

  const [tabValue, setTabValue] = useState(1);

  // skip 0 means page 1
  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
        ? pageState?.page
        : pageState?.page - 1;
  };

  useEffect(() => {
    setPaginationCount(getPaginationCount(pageState?.total, pageState?.pageSize));
  }, [pageState?.total]);


  const dispatch = useDispatch();

  //styles
  const classes = useStyles();

  const handleAllocateBankFieldsInputChange = (name, value) => {
    if (!value) {
      handleClearSearchList()
    }
    setSearchFieldsPrams({
      ...searchFieldsPrams,
      [name]: value?.trim(),
    });
  };


  const handleStatusDropDown = (name, value, reason) => {
    if (reason === "clear") {
      setSearchFieldsPrams({
        ...searchFieldsPrams,
        Bank_Allocation_Status: "",
      });
      setIsAllocationStatusDeSelected(true);
    } else {
      setIsAllocationStatusDeSelected(false);
      setSearchFieldsPrams({
        ...searchFieldsPrams,
        [name]: value?.trim(),
      });
    }
  }

  const searchQueryParams = () => {
    let getSearchQueryParams = "";

    const { Bank_Name, Bank_Transaction_Id, Bank_Allocation_Status } =
      searchFieldsPrams;

    if (Bank_Name) {
      getSearchQueryParams = `${getSearchQueryParams}&bankName=${Bank_Name}`;
    }

    if (Bank_Transaction_Id) {
      getSearchQueryParams = `${getSearchQueryParams}&transactionId=${Bank_Transaction_Id}`;
    }

    if (Bank_Allocation_Status) {
      getSearchQueryParams = `${getSearchQueryParams}&allocationStatus=${Bank_Allocation_Status}`;
    }

    return getSearchQueryParams;
  }

  const handleSearchBankTransactionList = async () => {

    try {
      let queryParams = `?skip=0&pageSize=${pageState?.pageSize}&allocated=${tabValue === 0 ? "Allocated" : "Unallocated"}`;

      const getSearchQueryParam = searchQueryParams();
      if (getSearchQueryParam) {
        queryParams = `${queryParams}${getSearchQueryParam}`;
      }

      // set loading indicator
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const response = await instance.get(
        `${UserAssignTxn}${queryParams}&user_id=${localStorage.getItem(
          "userID"
        )}`
      );

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ?? [],
          page: 1
        }));
        setIsSearchPerformed(true);
        setIsClearPerformed(false);
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (error) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.log("error", error);
    }
  };

  const isFilterBtnDisable = () => {
    let disabled = true;
    const { Bank_Name, Bank_Transaction_Id, Bank_Allocation_Status } =
      searchFieldsPrams;
    if (
      Bank_Name !== "" ||
      Bank_Transaction_Id !== "" ||
      Bank_Allocation_Status !== ""
    ) {
      disabled = false;
    }
    return disabled;
  };

  const handleClearSearchList = async () => {
    setIsClearPerformed(true);
    setIsSearchPerformed(false);

    // clear fields
    setSearchFieldsPrams({
      Bank_Name: "",
      Bank_Transaction_Id: "",
      Bank_Allocation_Status: "",
    });
  };

  useEffect(() => {
    instance
      .get(GetUsers)
      .then((data) => {
        setUsers([...new Set(data.data)]);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const handleSubmitAssing = async () => {
    try {
      const response = await instance.post(MutlipleAssignTxnUser, {
        user_login_id: selectedUser,
        Bank_Transaction_Ids: selectedRowsData.map((item) => ({ id: item.id })),
      });
      if (response.status === 200) {
        toast.success("Assign successful!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        handleCloseDialog();
        loadData();
      }
    } catch (err) {
      toast.error("Assign failed", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
      console.log("err", err);
    }
  };

  const handleAssignButtonClick = (params) => {
    setSelectedUser("");
    // Implement your logic here for handling the button click and opening the dialog
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    // Implement your logic here for handling the dialog close
    setDialogOpen(false);
  };

  // Function to handle individual checkbox change
  const handleCheckboxChange = (index, isChecked) => {
    const updatedSelectedRows = [...selectedRows];
    updatedSelectedRows[index] = isChecked;

    // Check if all checkboxes are selected
    const allSelected = updatedSelectedRows.every((row) => row);
    setSelectedRows(updatedSelectedRows);
    setSelectAll(allSelected);
  };

  // Function to handle "Select All" checkbox change
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const updatedSelectedRows = new Array(rows.length).fill(isChecked);
    setSelectedRows(updatedSelectedRows);
    setSelectAll(isChecked);
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "red",
    padding: "0px",
  };

  const subContainerStyle = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 2,
  };

  const workListText = {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "400",
    marginTop: "5px",
  };

  const rowStyle = {
    display: "flex",
  };

  const columnStyle = {
    flex: 1,
    padding: "10px",
    backgroundColor: "white", // Optional background color for better visibility
  };

  const [dropdownValues, setDropdownValues] = useState([]);
  const [dropdownValuesCurreny, setDropdownValuesCurrency] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [currentRow, setCurrentRow] = useState("");
  const [isOpenTxnDialog, setIsOpenTxnDialog] = useState(false);
  const [txnMode, setTxnMode] = useState("VIEW");

  const Allocation = "allocation";

  useEffect(() => {
    // Fetch dropdown values from your API
    instance
      .get(BrokerList)
      .then((response) => setDropdownValues(response?.data?.Broker_Branch_Name ?? []))
      .catch((error) =>
        console.error("Error fetching dropdown values:", error)
      );
  }, []);

  useEffect(() => {
    // Fetch dropdown values from your API
    instance
      .get(CurrencyList)
      .then((response) => setDropdownValuesCurrency(response?.data ?? []))
      .catch((error) =>
        console.error("Error fetching dropdown values:", error)
      );
  }, []);

  useEffect(() => {
    // Fetch sample data from the GET API
    instance
      .get(BankList)
      .then((response) => {
        setSampleData(response.data.bank_names);
      })
      .catch((error) => {
        console.error("Error fetching sample data:", error);
      });
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (localStorage.getItem("currentUser")) {
          var response = "";
          var id = localStorage.getItem("userID");
          response = await instance.get(userManagementGet + id + "/");
          if (response) {
            setBankPermissions(
              response.data.user_permissions.permissions_list[
              "Bank Transactions"
              ]
            );
          }
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };
    fetchUserData();
  }, []);

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (selectedOption) {
      // Call your API using Axios
      instance
        .get(GetDetailsByBank + selectedOption)
        .then((response) => {
          // Handle the API response
          setResponseData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [selectedOption]);

  const handleUploadOpen = () => {
    // Create an array of empty objects with the same structure as your rows
    const emptyRows = Array.from({ length: rows.length }, () => ({
      ...BANK_STATEMENT_ROW_DATA,
      PT_Receving_Bank_Account_Name_Entity: selectedOption,
    }));

    // Update the state with the array of empty objects
    setRows(emptyRows);
    setUploadOpen(true);
  };

  const [open, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  const handleDeleteConfirmed = async () => {
    try {
      // Send DELETE request to your API
      const response = await instance.delete(BankTransaction + idToDelete);

      // Check if the deletion was successful
      if (response.status === 200) {
        // If successful, show a success toast
        toast.success("Transaction deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setOpen(false);
        setIdToDelete(null);

        loadData();
        // If needed, update the state to reflect the deletion
        const updatedRows = rows.filter((row) => row.id !== idToDelete);
        setRows(updatedRows);
        // Perform any additional actions here
      } else {
        // If deletion was not successful, show an error toast
        toast.error(`Error deleting item: ${response.statusText}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (error) {
      // If an error occurs during deletion, show an error toast
      toast.error(`Error deleting item: ${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };
  const handleDeleteCancelled = () => {
    setOpen(false);
    setIdToDelete(null);
  };

  const handleActionButton = (currentRowData, currentTxnMode) => {
    setCurrentRow(currentRowData);
    setTxnMode(currentTxnMode);
    setIsOpenTxnDialog(true);
  };

  const columns = [
    {
      field: "assigned_date",
      headerName: "Assigned Date",
      width: 150,
      headerAlign: "center",
      flex: 0.6,
      valueGetter: (params) => params?.value,
      valueFormatter: (params) => {
        // Format the date using date-fns or any other date formatting library
        return format(new Date(params?.value), "dd-MM-yyyy")
      },
    },
    {
      field: "Payment_Receive_Date",
      headerName: "Payment Rcv Date",
      headerClassName: "split-header", 
      width: 150,
      headerAlign: "center",
      flex: 0.6,
      valueGetter: (params) => params?.value,
      valueFormatter: (params) => {
        // Format the date using date-fns or any other date formatting library
        return format(new Date(params?.value), "dd-MM-yyyy")
      },
    },
    {
      field: "Bank_Account_Name_Entity",
      headerName: "Bank Name",
      width: 150,
      headerAlign: "center",
      flex: 0.8,
      valueGetter: (params) => params?.row?.bank_details?.bank_name,
      renderCell: (params) => (
        <div>
          {params?.row?.bank_details?.bank_name?.length > 0
            ? params?.row?.bank_details.bank_name
            : ""}
        </div>
      ),
    },
    {
      field: "Bank_Transaction_Id",
      headerName: "Transaction ID",
      width: 150,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "Broker",
      headerName: "Broker Name",
      width: 150,
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => params?.row?.broker_information?.broker_name,
      renderCell: (params) => (
        <div>
          {params?.row?.broker_information?.broker_name?.length > 0
            ? params.row.broker_information?.broker_name
            : ""}
        </div>
      ),
    },
    // {
    //   field: "Allocation_Status",
    //   headerName: "Allocation Status",
    //   headerAlign: "center",
    //   flex: 0.9,
    //   sortable: true,
    //   width: 150,
    //   // valueGetter: (params) =>
    //   //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
    {
      field: "transaction_status",
      headerName: "Transaction Status",
      headerAlign: "center",
      flex: 0.9,
      sortable: true,
      width: 150,
      // valueGetter: (params) =>
      //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
      field: "Receivable_Amount",
      headerName: "Amount",
      width: 100,
      headerAlign: "center",
      flex: 0.6,
      valueGetter: (params) => params?.value,
      valueFormatter: (params) => {
        return numberFormatter(params.value)
      },
      cellClassName:"amount-table-cell"
    },
    {
      field: "Assigned_User",
      headerName: "Assign to",
      headerAlign: "center",
      flex: 0.6,
      width: 100,
      valueGetter: (params) => params?.row?.Assigned_User?.user_name,
      renderCell: (params) => (
        <>
          {
            params?.row?.Assigned_User?.user_name ?? ""
          }
        </>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerAlign: "center",
      description: "This column has a value getter and is not sortable.",
      flex: 0.8,
      width: 200,
      cellClassName: "actions",
      renderCell: ({ row }) => {
        return (
          <>
            <EditIcon onClick={() => handleActionButton(row, "EDIT")} />
            <CommonButton
              style={{
                // background: "#FF5A01",
                marginLeft: "5%",
                // color: "white",
                borderRadius: "20px",

              }}
              className="bankStatementBtn"
              text={"Allocate"}
              hoverColor="#EE3F00"
              handleClick={() => {
                if (row?.Broker_Branch) {
                  window.location.href = `/cash-allocation?txn_id=${row.Bank_Transaction_Id}`;
                } else {
                  handleActionButton(row, "EDIT")
                }
              }}
              disabled={row?.txn_category === 'M &A Fees' || row?.txn_category === 'Refund' || row?.txn_category === 'Non Premium'}
            />
          </>
        );
      },
    },
  ];

  const handleDialogClose = () => {
    setUploadOpen(false);
    setValidationStatus(Array(rows.length).fill(true));
  };
  const [rows, setRows] = useState([
    {
      Accounting_Month: "2024-01-11",
      PT_Receving_Bank_Account_Name_Entity: "",
      Receiving_Bank_Account: "",
      Broker_Branch: "",
      Payment_Receive_Date: "",
      Payment_Reference: "",
      Payment_Currency_Code: "",
      Bank_Currency_Code: "",
      Bank_Exchange_Rate: "0.00",
      Bank_Exchange_Charges: "0.00",
      Bank_Charges: "0.00",
      Receivable_Amount: "0.00",
      TL_Fees: "0.00",
      Currency: "",
      File_Name: null,
      Created_By: "",
      Analyst_Name: "",
      Uploaded_By: "",
      Broker: "",
      Allocation_Status: "Open",
    },
  ]);

  const [validationStatus, setValidationStatus] = useState(
    Array(rows.length).fill(true)
  );

  const loadData = async () => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      let queryParams = `skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize}&allocated=${tabValue === 0 ? "Allocated" : "Unallocated"}`;

      if (isSearchPerformed) {
        const getSearchQueryParam = searchQueryParams();
        if (getSearchQueryParam) {
          queryParams = `${queryParams}${getSearchQueryParam}`;
        }
      }


      const resp = await instance.get(
        `${UserAssignTxn}?${queryParams}&user_id=${localStorage.getItem("userID")}`
      );
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (resp.status === 200) {
        setPageState((old) => ({
          ...old,
          total: resp?.data?.count,
          data: resp?.data?.data ?? [],
        }));
        setPaginationCount(Math.floor(resp?.data?.count / pageState?.pageSize))
      } else {
        setPageState((old) => ({
          ...old,
          total: resp?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.error("err", err);
    }
  };

  useEffect(() => {
    // Fetch Banks List
    dispatch(getAccountsList());
    // Fetch Broker branches
    dispatch(getBrokerBranchesList());
    // Fetch Currency Details
    dispatch(getCurrencyList());
  }, []);


  const handleSubmit = async () => {
    // Check if any Payment_Receive_Date is in the future
    const hasFutureDate = rows.some((item) => {
      const paymentReceiveDate = new Date(item.Payment_Receive_Date);
      const today = new Date();
      return paymentReceiveDate > today;
    });

    rows["PT_Receving_Bank_Account_Name_Entity"] = selectedOption;

    if (hasFutureDate) {
      toast.error("Payment_Receive_Date cannot be in the future.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return; // Exit the function if there is a future date
    }

    rows.forEach((item) => {
      item["PT_Receving_Bank_Account_Name_Entity"] = selectedOption;
      item["Allocation_Status"] = "Open";
    });

    // Continue with the rest of the function if no future date is found

    try {
      // Make the POST request with the rows data
      const response = await instance.post(BankTransaction, rows);

      if (response.status === 200) {
        toast.success("Submission successful!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        handleDialogClose();
        loadData();
      }
    } catch (err) {
      toast.error("Submission failed. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleInputChange = (index, colName, value) => {
    const updatedRows = [...rows];
    updatedRows[index][colName] = value;
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        ...BANK_STATEMENT_ROW_DATA,
        PT_Receving_Bank_Account_Name_Entity: selectedOption,
      },
    ]);
  };


  const handleDeleteRow = () => {
    // Ensure there is at least one row remaining
    if (rows.length <= 1) {
      // Throw an error or show a message indicating that at least one row should remain
      console.error("At least one row should remain.");
      return;
    }

    const updatedRows = rows.filter((row, index) => !selectedRows[index]);
    setRows(updatedRows);

    // Clear selected rows and set Select All to false after deletion
    setSelectedRows([]);
    setSelectAll(false);
  };

  const handleSelectionModelChange = (newSelectionModel) => {
    setSelectionModel(newSelectionModel);
  };

  const selectedRowsData = pageState?.data?.filter((row) =>
    selectionModel.includes(row.id)
  );

  const handleReloadAccountsData = () => {
    loadData();
  };

  const isButtonDisabled = selectedRowsData.some(
    (item) => item.Assigned_Users.length > 0
  );

  // UseEffect
  useEffect(() => {
    if (isAllocationStatusDeSelected || isClearPerformed) {
      loadData();
    }
  }, [isAllocationStatusDeSelected, isClearPerformed]);

  useEffect(() => {
    if (!isAllocationStatusDeSelected) {
      loadData();
    }
  }, [pageState?.pageSize, pageState?.page]);

  useEffect(() => {
    loadData();
  }, [tabValue]);

  const handleTabChange = async (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div style={subContainerStyle}>
      <div>
        <div style={workListText}>
          <span style={{ color: "#FF5A01", fontSize: "24px" }}>
            Worklist Items
          </span>
          <Grid
            container
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            mt={"10px"}
            justifyContent={"flex-end"}
            sx={{ width: "185px" }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              style={{
                border: "1px solid black",
                borderRadius: "9px",
              }}
              aria-label="disabled tabs example"
            >
              <Tab
                style={{
                  borderRight: "1px solid black",
                  paddingLeft: "2px",
                  backgroundColor: tabValue === 0 ? "#FF5A01" : "",
                  color: tabValue === 0 ? "black" : "",
                }}
                label="Completed"
              />

              <Tab
                label="Open"
                style={{
                  backgroundColor: tabValue === 1 ? "#FF5A01" : "",
                  color: tabValue === 1 ? "black" : "",
                  paddingLeft: "2px",
                }}
              />
            </Tabs>
          </Grid>
        </div>

        <Grid
          container
          display={"flex"}
          flexDirection={"row"}
          mb={"5px"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            xs={12}
            gap={"12px"}
          >
            <Grid
              item
              xs={12}
              md={2.1}
              display={"flex"}
              flexDirection={"column"}
              gap={"4px"}
            >
              <FormLabel>Bank Name</FormLabel>
              <TextField
                value={searchFieldsPrams?.Bank_Name}
                onChange={(e) =>
                  handleAllocateBankFieldsInputChange(
                    "Bank_Name",
                    e.target.value
                  )
                }
                sx={{ width: "auto" }}
                size="small"
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={2.1}
              display={"flex"}
              flexDirection={"column"}
              gap={"4px"}
            >
              <FormLabel>Transaction Id</FormLabel>
              <TextField
                value={searchFieldsPrams?.Bank_Transaction_Id}
                onChange={(e) =>
                  handleAllocateBankFieldsInputChange(
                    "Bank_Transaction_Id",
                    e.target.value
                  )
                }
                sx={{ width: "auto" }}
                size="small"
              />
            </Grid>

            <Grid
              item
              display={"flex"}
              flexDirection={"column"}
              gap={"2px"}
              xs={12}
              md={2.1}
            >
              <FormLabel>Status</FormLabel>
              <CommonSelect
                placeholder="Select Status"
                value={searchFieldsPrams?.Bank_Allocation_Status ?? ""}
                handleChange={(event, value, reason) =>
                  handleStatusDropDown(
                    "Bank_Allocation_Status",
                    value,
                    reason,
                    event
                  )
                }
                options={allocationStatusList.map((option) => option?.label)}
              />
            </Grid>

            <Grid
              item
              display={"flex"}
              flexDirection={"column"}
              gap={"20px"}
            >
              <FormLabel></FormLabel>
              <CommonButton
                text="Search"
                className="bankStatementBtn"
                handleClick={handleSearchBankTransactionList}
                hoverColor="#EE3F00"
                disabled={pageState?.isLoading || isFilterBtnDisable()}
              />
            </Grid>
            
            <Grid
              item
              display={"flex"}
              flexDirection={"column"}
              gap={"20px"}
            >
              <FormLabel></FormLabel>
              <CommonButton
                text="Clear"
                handleClick={handleClearSearchList}
                className="bankStatementBtn"
                hoverColor="#EE3F00"
                disabled={pageState?.isLoading || isFilterBtnDisable()}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={1.5}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
            >
              <Grid
                item
                display={"flex"}
                flexDirection={"column"}
                gap={"20px"}
              >
                <FormLabel></FormLabel>
                {bankPermissions["Allocation/Assignee"] === "Y" ? (
                  <CommonButton
                    text="Assign"
                    disabled={isButtonDisabled}
                    handleClick={() => handleAssignButtonClick()}
                    sx={{
                      background: isButtonDisabled ? "#CCCCCC" : "#FF5A01",
                      color: isButtonDisabled ? "white" : "white", // Change text color accordingly
                      borderRadius: "20px",
                    }}
                    hoverColor="#FF5A01"
                  />
                ) : null}
              </Grid>

              <Grid
                item
                display={"flex"}
                flexDirection={"column"}
                gap={"20px"}
              >
                <FormLabel></FormLabel>
                {bankPermissions["Creation/Upload"] === "Y" ? (
                  <CommonButton
                    text="Add"
                    handleClick={() => handleUploadOpen()}
                    sx={allocateBankTransactionAdd}
                    hoverColor="#FF5A01"
                  />
                ) : null}
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </div>

      <div
        style={{
          height: 'calc(100vh - 295px)',
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          className={classes.pagination}
          loading={pageState?.isLoading}
          rows={pageState?.data ?? []}
          rowCount={pageState?.total}
          columns={columns}
          keepNonExistentRowsSelected
          pagination
          paginationMode="server"
          getRowId={(row) => row?.id}
          page={pageState.page - 1}
          pageSize={pageState?.pageSize ?? 25}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) => {
            return setPageState((old) => {
              setPaginationCount(getPaginationCount(pageState?.total, newPageSize))
              return { ...old, pageSize: newPageSize, page: 1 };
            });
          }}
          checkboxSelection
          sx={{
            "& .css-123glz5-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
              color: "red !important",
            },
            "& .MuiDataGrid-columnHeader[data-field='actions']": {
              minWidth: toggle?.isOpen ? "159.137px !important" : "185.137px !important"
            }
          }}
          startIcon={<AddIcon />}
          // disableColumnFilter
          // disableColumnSelector
          // disableDensitySelector
          // disableColumnMenu
          selectionModel={selectionModel}
          onSelectionModelChange={handleSelectionModelChange}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
        <Stack
          style={{
            position: "absolute",
            bottom: "8px",
          }}
        >
          <CommonPaginationContainer
            count={paginationCount + 1}
            pageState={pageState}
            setPageState={setPageState}
          />
        </Stack>
      </div>
      <Dialog
        open={uploadOPen}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {"Bank Transaction Creation Form"}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <hr style={{ width: "100%" }} />
        </DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: "20px" }}>
            <label htmlFor="dropdown" style={{ color: "black" }}>
              Select The Bank Name:{" "}
            </label>{" "}
            <br />
            <div>
              <select
                id="dropdown"
                name="dropdown"
                value={selectedOption}
                onChange={handleDropdownChange}
                // value={row.Payment_Receive_Date}
                // onChange={(e) => handleInputChange(index, 'Payment_Receive_Date', e.target.value)}
                style={{ height: "40px", borderRadius: "5px", width: "50%" }}
              >
                <option value="">Select Bank</option>
                {sampleData.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {/* <input
          type="file"
          id="fileUpload"
          name="fileUpload"
          onChange={handleFileChange}
          style={{ marginBottom: '10px' }}
        />
        <button onClick={handleUpload}>Upload</button> */}
              {/* Display the API response data */}
              {
                <>
                  <StyledCard
                    elevation={5}
                    style={{ marginTop: "20px", padding: "0px" }}
                  >
                    {/* <h2>API Response:</h2> */}
                    {/* <pre>{JSON.stringify(responseData, null, 2)}</pre> */}
                    <div style={containerStyle}>
                      <div style={rowStyle}>
                        <div style={columnStyle}>
                          Bank Name:&nbsp;{responseData?.bank_name}
                        </div>
                        {/* <div style={columnStyle}>Accounting Month:</div> */}
                        <div style={columnStyle}>
                          Currency Code:&nbsp;{responseData?.currency}
                        </div>
                        <div style={columnStyle}>
                          Bank Account Number:&nbsp;
                          {responseData?.account_number}
                        </div>
                      </div>
                      <div style={rowStyle}>
                        {/* <div style={columnStyle}>Bank Account Number:{responseData?.currency}</div> */}
                        {/* <div style={columnStyle}>Bank Txn ID:</div> */}
                        {/* <div style={columnStyle}>File Name:</div> */}
                      </div>
                    </div>
                  </StyledCard>
                </>
              }
            </div>
          </div>
          <StyledCard
            elevation={6}
            className="searchdiv"
            sx={{ backgroundColor: "#ffff" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p style={{ color: "black" }}>Transactions</p>
              <div>
                <button onClick={handleAddRow} style={{ color: "#4C4CE2" }}>
                  + Add
                </button>
                <button onClick={handleDeleteRow} style={{ color: "#FF5A01" }}>
                  {" "}
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.875 0.75H7.125C7.33211 0.75 7.5 0.917893 7.5 1.125V1.875H4.5V1.125C4.5 0.917893 4.66789 0.75 4.875 0.75ZM8.25 1.875V1.125C8.25 0.50368 7.74632 0 7.125 0H4.875C4.25368 0 3.75 0.50368 3.75 1.125V1.875H1.87924C1.87669 1.87497 1.87414 1.87497 1.87158 1.875H1.125C0.917893 1.875 0.75 2.04289 0.75 2.25C0.75 2.45711 0.917893 2.625 1.125 2.625H1.5288L2.16837 10.6196C2.23074 11.3992 2.88155 12 3.66359 12H8.33641C9.11845 12 9.76926 11.3992 9.83163 10.6196L10.4712 2.625H10.875C11.0821 2.625 11.25 2.45711 11.25 2.25C11.25 2.04289 11.0821 1.875 10.875 1.875H10.1284C10.1259 1.87497 10.1233 1.87497 10.1208 1.875H8.25ZM9.7188 2.625L9.08402 10.5598C9.05284 10.9496 8.72743 11.25 8.33641 11.25H3.66359C3.27257 11.25 2.94716 10.9496 2.91598 10.5598L2.2812 2.625H9.7188ZM4.10298 3.37565C4.30973 3.36349 4.48719 3.52123 4.49935 3.72798L4.87435 10.103C4.88651 10.3097 4.72877 10.4872 4.52202 10.4994C4.31527 10.5115 4.13781 10.3538 4.12565 10.147L3.75065 3.77202C3.73849 3.56527 3.89623 3.38781 4.10298 3.37565ZM7.89702 3.37565C8.10377 3.38781 8.26151 3.56527 8.24935 3.77202L7.87435 10.147C7.86219 10.3538 7.68473 10.5115 7.47798 10.4994C7.27123 10.4872 7.11349 10.3097 7.12565 10.103L7.50065 3.72798C7.51281 3.52123 7.69027 3.36349 7.89702 3.37565ZM6 3.375C6.20711 3.375 6.375 3.54289 6.375 3.75V10.125C6.375 10.3321 6.20711 10.5 6 10.5C5.79289 10.5 5.625 10.3321 5.625 10.125V3.75C5.625 3.54289 5.79289 3.375 6 3.375Z"
                      fill="#EE3F00"
                    />
                  </svg>{" "}
                  Delete
                </button>
              </div>
            </div>

            {/* <ValidatorForm
                         onError={() => null}
                        className="leadsearch customform" style={{}}>
                        <Grid container spacing={3}>
                        </Grid>
                    </ValidatorForm> */}
            <TableContainer style={{ maxHeight: "300px", overflowX: "clip" }}>
              <Table>
                <TableHead
                  style={{
                    backgroundColor: "#262671",
                    color: "white",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  <TableRow>
                    {/* <TableCell>Accounting_Month</TableCell> */}
                    {/* <TableCell>PT_Receving_Bank_Account_Name_Entity</TableCell> */}
                    {/* <TableCell>Receiving_Bank_Account</TableCell> */}
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "12px",
                        height: "44px",
                        padding: "0px",
                      }}
                    >
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "12px",
                        height: "44px",
                      }}
                    >
                      Payment <br />
                      Receive Date
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "12px",
                        height: "44px",
                        padding: "0px",
                      }}
                    >
                      Broker Branch
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "12px",
                        height: "44px",
                      }}
                    >
                      Bank <br />
                      Currency Code
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "12px",
                        height: "44px",
                      }}
                    >
                      Receivable <br />
                      Amount
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "12px",
                        height: "44px",
                      }}
                    >
                      Payment <br />
                      Currency Code
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "12px",
                        height: "44px",
                      }}
                    >
                      Bank <br />
                      Exchange Rate
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "12px",
                        height: "44px",
                      }}
                    >
                      Bank Exchange <br />
                      Charges
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "12px",
                        height: "44px",
                      }}
                    >
                      Payment <br />
                      Reference
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "12px",
                        height: "44px",
                      }}
                    >
                      Bank <br /> Charges
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={index}
                      style={{ border: "1px solid #ddd", margin: 0 }}
                    >
                      <TableCell sx={{ height: "20px", padding: "0px" }}>
                        <Checkbox
                          checked={selectedRows[index] || false}
                          onChange={(e) =>
                            handleCheckboxChange(index, e.target.checked)
                          }
                        />
                      </TableCell>
                      <TableCell sx={{ height: "20px", padding: "3px" }}>
                        <input
                          type="date"
                          value={row.Payment_Receive_Date}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "Payment_Receive_Date",
                              e.target.value
                            )
                          }
                          style={{
                            width: "152px",
                            height: "32",
                            padding: "0px",
                          }}
                          placeholder="Enter Details Here"
                        />
                        {validationStatus[index]?.Payment_Receive_Date && (
                          <span style={{ color: "red" }}>
                            {validationStatus[index]?.Payment_Receive_Date}
                          </span>
                        )}
                      </TableCell>
                      <TableCell sx={{ height: "20px", padding: "3px" }}>
                        <Select
                          value={row.Broker_Branch}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "Broker_Branch",
                              e.target.value
                            )
                          }
                          sx={{
                            width: "152px",
                            height: "32px",
                            padding: "0px",
                          }}
                          size="small"
                          placeholder="Select Here"
                        >
                          {/* Map through your list of dropdown values */}
                          {dropdownValues.map((option) => (
                            <MenuItem
                              key={option}
                              value={option}
                              sx={{ top: "500" }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        {validationStatus[index]?.Broker && (
                          <span style={{ color: "red" }}>
                            {validationStatus[index]?.Broker_Branch}
                          </span>
                        )}
                      </TableCell>
                      <TableCell sx={{ height: "20px", padding: "3px" }}>
                        {/* <TextField
                    value={row.Bank_Currency_Code}
                    onChange={(e) => handleInputChange(index, 'Bank_Currency_Code', e.target.value)}
                    sx={{width:"152px",height:"32",padding:"0px"}}
                    size="small"
                    placeholder='Enter Details Here'
                  /> */}
                        <Select
                          value={row.Bank_Currency_Code}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "Bank_Currency_Code",
                              e.target.value
                            )
                          }
                          sx={{
                            width: "152px",
                            height: "32px",
                            padding: "0px",
                          }}
                          size="small"
                          placeholder="Select Here"
                        >
                          {/* Map through your list of dropdown values */}
                          {dropdownValuesCurreny?.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.currency_code}
                              sx={{ top: "500" }}
                            >
                              {option.currency_code}
                            </MenuItem>
                          ))}
                        </Select>
                        {validationStatus[index]?.Bank_Currency_Code && (
                          <span style={{ color: "red" }}>
                            {validationStatus[index]?.Bank_Currency_Code}
                          </span>
                        )}
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={row.Receivable_Amount}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "Receivable_Amount",
                              e.target.value
                            )
                          }
                          sx={{ width: "100px" }}
                          size="small"
                          type="number" // Set the input type to "number"
                          InputProps={{
                            inputProps: {
                              min: 0, // Set minimum value to 0 if needed
                              onInput: (e) => {
                                e.preventDefault();
                                const value = e.target.value;
                                const regex = /^\d*\.?\d*$/; // Regular expression for numeric input
                                if (regex.test(value) || value === "") {
                                  handleInputChange(
                                    index,
                                    "Receivable_Amount",
                                    value
                                  );
                                }
                              },
                            },
                          }}
                        />
                        {validationStatus[index]?.Receivable_Amount && (
                          <span style={{ color: "red" }}>
                            {validationStatus[index]?.Receivable_Amount}
                          </span>
                        )}
                      </TableCell>
                      <TableCell sx={{ height: "20px", padding: "3px" }}>
                        <Select
                          value={row.Payment_Currency_Code}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "Payment_Currency_Code",
                              e.target.value
                            )
                          }
                          sx={{
                            width: "152px",
                            height: "32px",
                            padding: "0px",
                          }}
                          size="small"
                          placeholder="Select Here"
                        >
                          {/* Map through your list of dropdown values */}
                          {dropdownValuesCurreny?.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.currency_code}
                              sx={{ top: "500" }}
                            >
                              {option.currency_code}
                            </MenuItem>
                          ))}
                        </Select>
                        {validationStatus[index]?.Payment_Currency_Code && (
                          <span style={{ color: "red" }}>
                            {validationStatus[index]?.Payment_Currency_Code}
                          </span>
                        )}
                      </TableCell>

                      <TableCell sx={{ height: "20px", padding: "3px" }}>
                        <TextField
                          value={row.Payment_Reference}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "Payment_Reference",
                              e.target.value
                            )
                          }
                          sx={{ width: "152px", height: "32", padding: "0px" }}
                          size="small"
                          placeholder="Enter Details Here"
                        />
                        {validationStatus[index]?.Payment_Reference && (
                          <span style={{ color: "red" }}>
                            {validationStatus[index]?.Payment_Reference}
                          </span>
                        )}
                      </TableCell>

                      <TableCell sx={{ height: "20px", padding: "3px" }}>
                        <TextField
                          value={row.Bank_Exchange_Rate}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "Bank_Exchange_Rate",
                              e.target.value
                            )
                          }
                          sx={{ width: "100px" }}
                          size="small"
                          type="number" // Set the input type to "number"
                          InputProps={{
                            inputProps: {
                              min: 0, // Set minimum value to 0 if needed
                              onInput: (e) => {
                                e.preventDefault();
                                const value = e.target.value;
                                const regex = /^\d*\.?\d*$/; // Regular expression for numeric input
                                if (regex.test(value) || value === "") {
                                  handleInputChange(
                                    index,
                                    "Bank_Exchange_Rate",
                                    value
                                  );
                                }
                              },
                            },
                          }}
                        />
                        {validationStatus[index]?.Bank_Exchange_Rate && (
                          <span style={{ color: "red" }}>
                            {validationStatus[index]?.Bank_Exchange_Rate}
                          </span>
                        )}
                      </TableCell>
                      <TableCell sx={{ height: "20px", padding: "3px" }}>
                        <TextField
                          value={row.Bank_Exchange_Charges}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "Bank_Exchange_Charges",
                              e.target.value
                            )
                          }
                          sx={{ width: "100px" }}
                          size="small"
                          type="number" // Set the input type to "number"
                          InputProps={{
                            inputProps: {
                              min: 0, // Set minimum value to 0 if needed
                              onInput: (e) => {
                                e.preventDefault();
                                const value = e.target.value;
                                const regex = /^\d*\.?\d*$/; // Regular expression for numeric input
                                if (regex.test(value) || value === "") {
                                  handleInputChange(
                                    index,
                                    "Bank_Exchange_Charges",
                                    value
                                  );
                                }
                              },
                            },
                          }}
                        />
                        {validationStatus[index]?.Bank_Exchange_Charges && (
                          <span style={{ color: "red" }}>
                            {validationStatus[index]?.Bank_Exchange_Charges}
                          </span>
                        )}
                      </TableCell>

                      <TableCell>
                        <TextField
                          value={row.Bank_Charges}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "Bank_Charges",
                              e.target.value
                            )
                          }
                          sx={{ width: "100px" }}
                          size="small"
                          type="number" // Set the input type to "number"
                          InputProps={{
                            inputProps: {
                              min: 0, // Set minimum value to 0 if needed
                              onInput: (e) => {
                                e.preventDefault();
                                const value = e.target.value;
                                const regex = /^\d*\.?\d*$/; // Regular expression for numeric input
                                if (regex.test(value) || value === "") {
                                  handleInputChange(
                                    index,
                                    "Bank_Charges",
                                    value
                                  );
                                }
                              },
                            },
                          }}
                        />
                        {validationStatus[index]?.Bank_Charges && (
                          <span style={{ color: "red" }}>
                            {validationStatus[index]?.Bank_Charges}
                          </span>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Button onClick={handleAddRow}>Add Row</Button> */}
          </StyledCard>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "flex-end", paddingTop: "0px" }}
        >
          <CommonButton
            text="Reset"
            sx={allocateBankAddReset}
            handleClick={() => setUploadOpen(false)}
          />
          <CommonButton
            text="submit"
            sx={allocateBankAddSubmit}
            handleClick={handleSubmit}
            hoverColor="#FF5A01"
          />
        </DialogActions>
      </Dialog>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Assign To</DialogTitle>
        <hr style={{ width: "100%" }} />

        <DialogContent>
          <FormControl style={{ minWidth: "200px", marginTop: "10px" }}>
            <label>Assigning to </label>
            <Select
              id="assigningTo"
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              style={{ color: "black" }}
            >
              {users?.map((user, index) => (
                <MenuItem key={index} value={user.id}>
                  {user?.user_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Add another field here */}
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "end" }}>
          <CommonButton
            text="Cancel"
            sx={assignToCancel}
            handleClick={handleCloseDialog}
          />
          <CommonButton
            text="Assign"
            sx={assignTo}
            handleClick={handleSubmitAssing}
            hoverColor="#FF5A01"
          />
        </DialogActions>
      </Dialog>

      <DialogBox
        title="Delete Confirmation"
        body="Are you sure you want to delete this item?"
        confirmText="Delete"
        isOpen={open}
        onCancel={handleDeleteCancelled}
        onConfirm={handleDeleteConfirmed}
      />
      {isOpenTxnDialog && (
        <ViewAndEditBankTransaction
          isOpenTxnDialog={isOpenTxnDialog}
          setIsOpenTxnDialog={setIsOpenTxnDialog}
          txnMode={txnMode}
          txnData={currentRow}
          allocation={Allocation}
          handleReloadAccountsData={handleReloadAccountsData}
          navigatedFrom="BANK_TRANSACTION"
        />
      )}
      <ToastContainer />
    </div>
  );
}
