import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useFileUpload } from "./hooks";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";

const WrideDetails = {
  height: 50,
  width: "calc(100% - 35px)",
  padding: 15,
};

const History = ({ history }) => {
  return (
    <div className="cash-allocation-dialogue-container">
      <TableContainer sx={{ height: 400, overflow: "auto" }}>
        <Table>
          <TableHead className="table-head">
            <TableRow>
              <TableCell className="table-cell">File Name</TableCell>
              <TableCell className="table-cell p-0">File Details</TableCell>
              <TableCell className="table-cell">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {history?.map((row, index) => (
              <TableRow key={index} className="table-row">
                <TableCell className="table-cell">
                  {row.document_name}
                </TableCell>
                <TableCell className="table-cell">
                  {row.document_details}
                </TableCell>
                <TableCell className="table-cell">
                  <Button
                    color="warning"
                    variant="contained"
                    component="a"
                    href={row.document_file}
                    target="_blank"
                    sx={{ minWidth: "unset", width: "40px" }}
                  >
                    <RemoveRedEyeIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const FileUpload = ({ transaction }) => {
  const { history, formData, setFormData, submit } = useFileUpload(transaction);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showHistory, setShowHistory] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShowHistory(!!history.length);
  }, [history?.length]);

  const handleOpenDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, [setIsDialogOpen]);
  
  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
    setFormData({});
    setShowHistory(true);
  }, [setIsDialogOpen]);

  const handleSubmitUpload = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      await submit();
      setLoading(false);
      setFormData({});
      setShowHistory(true);
    },
    [setIsDialogOpen, submit]
  );

  const handleInputChange = useCallback(
    (e) => {
      const { name, value, files } = e.target;
      const allowedFileTypes = [
        "text/csv",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", 
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "image/jpeg",
        "image/jpg"
      ];

      if (name === "file" && files.length > 0) {
        const fileArray = Array.from(files);

        const validFiles = fileArray.filter((currentFile) =>
          allowedFileTypes.includes(currentFile.type)
        );

        if (validFiles.length === 0) {
          toast.error("Unsupported file format.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setFormData({});
          return;
        };

        if (validFiles.length > 5) {
          toast.error("Maximum of 5 files only can be uploaded at a time", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setFormData({});
          return;
        }

        fileArray.forEach((currentFile) => {
          if (currentFile?.size > 20 * 1024 * 1024) {
            toast.error("Maximum allowed file size is 20MB", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            setFormData({});
            return;
          }
        });

        setFormData((data) => ({
          ...data,
          [name]: validFiles,
        }));
      }
    },
    [setFormData]
  );

  const handleTextInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  return (
    <>
      <span className="file-download-icon" onClick={handleOpenDialog}>
        Files: <AttachFileIcon />
      </span>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth>
        <form onSubmit={handleSubmitUpload}>
          <DialogTitle style={{ background: "#FCFCFC" }}>Files</DialogTitle>
          <DialogContent>
            {showHistory ? (
              <History setShowHistory={setShowHistory} history={history} />
            ) : (
              <>
                <Grid container>
                  <Grid item xs={12}>
                    <span style={{ fontSize: 13, color: "#17191B" }}>
                      File Details
                    </span>

                    <TextareaAutosize
                      style={WrideDetails}
                      name="document_details"
                      value={formData.document_details}
                      onChange={handleTextInputChange}
                      placeholder="Write details here"
                      disabled={loading}
                    />
                  </Grid>

                  <Grid item xs={12} marginTop={2}>
                    <Button
                      color="inherit"
                      variant="outlined"
                      component="label"
                    >
                      Select New File to Upload
                      <input
                        type="file"
                        hidden
                        name="file"
                        accept=".doc,.docx,.xls,.xlsx,.csv,.pdf,.jpg,.jpeg"
                        onChange={handleInputChange}
                        multiple
                        required
                        disabled={loading}
                      />
                    </Button>
                    {formData?.file?.length > 0 && (
                      <>
                        <span style={{ fontSize: 13, marginTop: "15px" }}>
                          Selected Files
                        </span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            overflowY: "auto",
                            maxHeight: 300,
                          }}
                        >
                          {formData.file.map((item) => {
                            return (
                              <span className="file-name">{item?.name}</span>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "flex-end", padding: "15px 25px" }}
          >
            <Button
              onClick={() => setShowHistory(!showHistory)}
              color={showHistory ? "warning" : "inherit"}
              variant={showHistory ? "contained" : "outlined"}
              sx={{ borderRadius: 100 }}
              disabled={loading}
            >
              {showHistory ? "Add" : "History"}
            </Button>
            <Button
              onClick={handleCloseDialog}
              color="inherit"
              variant="outlined"
              sx={{ borderRadius: 100, border: "1px solid #BCBDBF" }}
              disabled={loading}
            >
              Close
            </Button>
            {!showHistory &&
              (loading ? (
                <LoadingButton loading>Uploading...</LoadingButton>
              ) : (
                <Button
                  variant="contained"
                  color="warning"
                  sx={{ borderRadius: 100 }}
                  type="submit"
                >
                  Upload
                </Button>
              ))}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default FileUpload;
