import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Stack, Grid, Input, FormLabel, TextField, Checkbox, Radio } from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { useStyles } from "./paymentUpdatesContainerStyles";
import { CommonPaginationContainer, CommonSelect } from "../common";
import { CommonButton } from "../common/commonButton";
import instance from "../../redux/api";
import { paymentTreasury, getAllPaymentIds, getPayoutSummary } from "../../Services/api";
import { getPaginationCount } from "../CashAllocation/utils";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';


const CoversheetList = () => {
  const { search } = useLocation();
  const policyNo = new URLSearchParams(search).get("policyNo");
  const fileName = new URLSearchParams(search).get("fileName");
  const paymentType = new URLSearchParams(search).get("paymentType");

  const [paginationCount, setPaginationCount] = useState(0);
  const [paymentIds, setPaymentIds] = useState([]);
  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [isClearPerformed, setIsClearPerformed] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState([]);

  const [searchFieldParams, setSearchFieldsParams] = useState({
    type_of_payment: paymentType,
    policy_no: policyNo,
    bdx_file_name: fileName,
    payment_id: "",
  });

  const toggle = useSelector((state) => state.toggleSideMenu);

  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
      ? pageState?.page
      : pageState?.page - 1;
  };

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  //styles
  const classes = useStyles();

  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  const paymentTypes = [
    "Syndicate Payment",
    "Internal Broking",
    "MGA Commission",
    "Rebate",
  ];

  const paymentStatus = [
    {label: "Paid", value: 'paid'},
    {label: "Not Paid", value: 'unpaid'},
    {label: "Pending", value: 'pending'}
  ];

  const getPaymentIds = async () => {
    const response = await instance.get(`${getAllPaymentIds}`);
    setPaymentIds(response?.data?.data);
  };

  const searchQueryParams = () => {
    let getsearchQueryParams = "";

    const { type_of_payment, policy_no, bdx_file_name, payment_id } =
      searchFieldParams;

    if (type_of_payment) {
      getsearchQueryParams = `&type_of_payment=${type_of_payment}`;
    }

    if (policy_no) {
      getsearchQueryParams = `${getsearchQueryParams}&certificate_ref=${policy_no}`;
    }

    if (bdx_file_name) {
      getsearchQueryParams = `${getsearchQueryParams}&file_name=${bdx_file_name}`;
    }

    if (payment_id) {
      getsearchQueryParams = `${getsearchQueryParams}&payment_id=${payment_id}`;
    }

    return getsearchQueryParams;
  };

  const loadSearchTxnData = async () => {
    try {
      let queryParams = `skip=0&pageSize=${pageState?.pageSize}&payment_status=true`;

      const getSearchQueryParam = searchQueryParams();
      if (getSearchQueryParam) {
        queryParams = `${queryParams}${getSearchQueryParam}`;
      }

      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      // const response = await instance.get(`${paymentTreasury}?${queryParams}`);
      const response = await instance.get(`${getPayoutSummary}?${queryParams}`);
      

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      setIsSearchPerformed(true);
      setIsClearPerformed(false);

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ? response?.data?.data : [],
        }));
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count ?? 0,
          data: [],
          page: 1, // required for sending skip as 0
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));
      console.log("err", err);
    }
  };

  const loadData = async () => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      let queryParams = `skip=${currentSkipNumber()}&pageSize=${
        pageState?.pageSize
      }&payment_status=true`;

      if (isSearchPerformed) {
        const getSearchQueryParam = searchQueryParams();
        if (getSearchQueryParam) {
          queryParams = `${queryParams}${getSearchQueryParam}`;
        }
      } else {
        // If we get information while performing route navigation
        if (!isClearPerformed) {
          if (policyNo) {
            queryParams = `${queryParams}&certificate_ref=${policyNo}`;
            setSearchFieldsParams({
              ...searchFieldParams,
              policy_no: policyNo,
            });
          }

          if (fileName) {
            queryParams = `${queryParams}&file_name=${fileName}`;
            setSearchFieldsParams({
              ...searchFieldParams,
              bdx_file_name: fileName,
            });
          }

          if (paymentType) {
            queryParams = `${queryParams}&type_of_payment=${paymentType}`;
            setSearchFieldsParams({
              ...searchFieldParams,
              type_of_payment: paymentType,
            });
          }
        }
      }

      const response = await instance.get(`${getPayoutSummary}?${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ?? [],
        }));
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.error("err", err);
    }
  };

  const handleSelectDropdown = (name, value, reason) => {
    if (reason === "clear") {
      loadData();
    }
    setSearchFieldsParams({
      ...searchFieldParams,
      [name]: value ?? "",
    });
  };

  const handleClearSearchList = async () => {
    setIsSearchPerformed(false);
    setIsClearPerformed(true);

    // clear fields
    setSearchFieldsParams({
      type_of_payment: "",
      policy_no: "",
      bdx_file_name: "",
      payment_id: "",
    });
  };

  // Add these state variables near other useState declarations
  const [openReleaseModal, setOpenReleaseModal] = useState(false);
  const [releasePaymentData, setReleasePaymentData] = useState({
    payment_complete_status: '',
    wire_ref: '',
    payment_date: ''
  });
  const [isButtonEnable, setIsButtonEnable] = useState(false);

  // Add these handler functions
  const handleReleasePaymentClick = () => {
    setOpenReleaseModal(true);
  };

  const handleCloseReleaseModal = () => {
    setOpenReleaseModal(false);
    setReleasePaymentData({
      payment_complete_status: '',
      wire_ref: '',
      payment_date: ''
    });
  };

  useEffect(() => {
    setIsButtonEnable(releasePaymentData.payment_complete_status === "" || releasePaymentData.wire_ref === "" || releasePaymentData.payment_date === "")
  }, [releasePaymentData])

  const handleReleasePaymentSubmit = async () => {
    try {
      // Modify the releasePaymentData to only send the label for payment_complete_status
      const payloadData = {
        ...releasePaymentData,
        payment_complete_status: releasePaymentData.payment_complete_status?.label || releasePaymentData.payment_complete_status
      };
      console.log(getPayoutSummary, selectedRecord, releasePaymentData)
      // Add your API call here to save the payment data
      const response = await instance.patch(
        `${getPayoutSummary}${selectedRecord}/`,
        payloadData
      );

      if (response) {
        toast.success('Payment released successfully!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        handleCloseReleaseModal();
        loadData(); // Refresh the grid
      }
    } catch (err) {
      console.error(err);
      toast.error('Unable to release payment', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  // const handleReleasePaymentClick = () => {
  //   const allData = pageState.data;
  //   let payload = [];
  //   selectedRecords.forEach(value => {
  //     const currentData = allData.find(data => data.id == value)
  //     payload.push({
  //       id: value,
  //       payment_status: currentData.payment_status,
  //       payment_date: currentData.payment_date,
  //       wire_no: currentData.wire_no
  //     })
  //   })
  // }

  const handleSavePartnerPayment = async (currentClickedRow) => {
    try {
      const paymentObj = {
        payment_date: currentClickedRow?.payment_date,
        wire_ref: currentClickedRow?.wire_ref,
        payment_complete_status: currentClickedRow?.payment_complete_status,
      };

      const response = await instance.patch(
        `${paymentTreasury}${currentClickedRow?.id}`,
        paymentObj
      );

      if (response) {
        toast.success(`Payment status saved successfully !`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error("Unable to Save Partner Payment", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to Save Partner Payment", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const updateTreasuryRowFields = (currentRow, fieldName, valueToUpdate) => {
    let currentPageData = pageState.data;

    const getCurrentRowId = currentRow?.id;
    const getIndex = currentPageData.findIndex(
      (item) => item?.id === getCurrentRowId
    );
    currentPageData[getIndex][fieldName] = valueToUpdate;

    setPageState({
      ...pageState,
      data: currentPageData,
    });
  };

  const [selectedRecord, setSelectedRecord] = useState(null);

  // Update handleRadioChange to handle single selection
  const handleRadioChange = (e) => {
    const { value, checked } = e.target
    console.log(value, checked)
    if(checked) {
      setSelectedRecord(Number(value))
    } else {
      setSelectedRecord(null)
    }
  };

  // Update the checkbox render to use Radio instead
  const renderSelectionCell = (params) => (
    <>
      <Radio
        onChange={(e) => handleRadioChange(e)}
        value={params?.row?.id}
        name="file-selection"
        style={{ color: "#FF5A01" }}
        checked={selectedRecord === params?.row?.id}
      />
    </>
  );

  // const partnerPaymentColumns = [
  //   {
  //     field: "payment_id",
  //     headerName: "Payment ID",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "category",
  //     headerName: "Category",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "file_name",
  //     headerName: "BDX File Name",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "certificate_ref",
  //     headerName: "Policy No",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "Partner_Name",
  //     headerName: "Partner Name",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "coverholder_name",
  //     headerName: "Coverholder Name",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "bank_account",
  //     headerName: "Bank Account",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "sett_currency",
  //     headerName: "Settlement Currency",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "net_transfer",
  //     headerName: "Net Payment",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "payment_date",
  //     headerName: "Payment Date",
  //     headerAlign: "center",
  //     width: 160,
  //     renderCell: (params) => (
  //       <div>
  //         <Input
  //           name="payment_date"
  //           onChange={(event) => updateTreasuryRowFields(params?.row, "payment_date", event.target.value)}
  //           value={
  //             params?.row?.payment_date?.substring(0, 10) ?? ""
  //           }
  //           type="date"
  //         />
  //       </div>
  //     ),
  //   },
  //   // {
  //   //   field: "wire_no",
  //   //   headerName: "Wire #",
  //   //   headerAlign: "center",
  //   //   width: 160,
  //   //   renderCell: (params) => (
  //   //     <div>
  //   //       <Input
  //   //         name="wire_no"
  //   //         type="number"
  //   //         onChange={(event) => updateTreasuryRowFields(params?.row, "wire_no", event.target.value)}
  //   //         value={params?.row?.wire_no ?? ""}
  //   //       />
  //   //     </div>
  //   //   ),
  //   // },
  //   {
  //     field: "payment_status",
  //     headerName: "Payment Status",
  //     headerAlign: "center",
  //     width: 180,
  //     renderCell: (params) => (
  //       <CommonSelect
  //         placeholder="Payment Status"
  //         value={params?.row?.payment_status ?? ""}
  //         handleChange={(event, value, reason) =>
  //           updateTreasuryRowFields(params?.row, "payment_status", value)
  //         }
  //         customStyles={{ minWidth: "150px", maxWidth: '200px' }}
  //         options={paymentStatus?.map((option) => option) || []}
  //       />
  //     ),
  //   },
  //   {
  //     field: "actions",
  //     headerName: "Actions",
  //     headerAlign: "center",
  //     width: 180,
  //     renderCell: (params) => {
  //       return (
  //         <CommonButton
  //           text="Save"
  //           handleClick={() => handleSavePartnerPayment(params?.row)}
  //           hoverColor="#EE3F00"
  //         // disabled={params?.row?.comment}
  //         />
  //       );
  //     },
  //   },
  // ];

  // const syndicatePaymentColumns = [
  //   {
  //     field: "payment_id",
  //     headerName: "Payment ID",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "category",
  //     headerName: "Category",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "file_name",
  //     headerName: "BDX File Name",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "certificate_ref",
  //     headerName: "Policy No",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "coverholder_name",
  //     headerName: "Coverholder Name",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "bank_account",
  //     headerName: "Payment Received Bank Number",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "coverholder_entity",
  //     headerName: "Payment Received Bank Name",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "sett_currency",
  //     headerName: "Settlement Currency",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "net_transfer",
  //     headerName: "Net Payment",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  // ];

  // const commissionPaymentColumns = [
  //   {
  //     field: "payment_id",
  //     headerName: "Payment ID",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "category",
  //     headerName: "Category",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "file_name",
  //     headerName: "BDX File Name",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "certificate_ref",
  //     headerName: "Policy No",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "coverholder_name",
  //     headerName: "Coverholder Name",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "bank_account",
  //     headerName: "Payment Received Bank Number",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "coverholder_entity",
  //     headerName: "Payment Received Bank Name",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "sett_currency",
  //     headerName: "Settlement Currency",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "net_transfer",
  //     headerName: "Net Payment",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  // ];

  // const rebatePaymentColumns = [
  //   {
  //     field: "payment_id",
  //     headerName: "Payment ID",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "category",
  //     headerName: "Category",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "file_name",
  //     headerName: "BDX File Name",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "certificate_ref",
  //     headerName: "Policy No",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "coverholder_name",
  //     headerName: "Coverholder Name",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "bank_account",
  //     headerName: "Payment Received Bank Number",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "coverholder_entity",
  //     headerName: "Payment Received Bank Name",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "sett_currency",
  //     headerName: "Settlement Currency",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "net_transfer",
  //     headerName: "Net Payment",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  // ];

  // const correctivePaymentColumns = [
  //   {
  //     field: "payment_id",
  //     headerName: "Payment ID",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "file_name",
  //     headerName: "BDX File Name",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "certificate_ref",
  //     headerName: "Policy No",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "coverholder_name",
  //     headerName: "Coverholder Name",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "bank_account",
  //     headerName: "Payment Received Bank Number",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "coverholder_entity",
  //     headerName: "Payment Received Bank Name",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "sett_currency",
  //     headerName: "Settlement Currency",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  //   {
  //     field: "net_transfer",
  //     headerName: "Net Payment",
  //     headerAlign: "center",
  //     width: 160,
  //   },
  // ];

  const syndicatePaymentColumns = [
    {
      headerName: "",
      headerAlign: "center",
      flex: 0.1,
      type: "actions",
      renderCell: renderSelectionCell,
    },
    {
      field: "coverholder_name",
      headerName: "Coverholder Name",
      headerAlign: "center",
      width: 300,
    },
    {
      field: "final_bank_account",
      headerName: "Final Bank Account",
      headerAlign: "center",
      width: 200,
    },
    {
      field: "final_bank_name",
      headerName: "Final Bank Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account_name",
      headerName: "To Bank Account Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account",
      headerName: "To Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "settlement_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "sum_of_final_net_premium_settlement_currency",
      headerName: "Total",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      headerAlign: "center",
      width: 160,
      // renderCell: (params) => (
      //   <div>
      //     <Input
      //       name="payment_date"
      //       onChange={(event) => updateTreasuryRowFields(params?.row, "payment_date", event.target.value)}
      //       value={
      //         params?.row?.payment_date?.substring(0, 10) ?? ""
      //       }
      //       type="date"
      //     />
      //   </div>
      // ),
    },
    {
      field: "payment_complete_status",
      headerName: "Payment Status",
      headerAlign: "center",
      width: 180,
      // renderCell: (params) => (
      //   <Input
      //       name="payment_status"
      //       type="text"
      //       onChange={(event) => updateTreasuryRowFields(params?.row, "payment_status", event.target.value)}
      //       value={params?.row?.payment_status ?? ""}
      //     />
      //   // <CommonSelect
      //   //   placeholder="Payment Status"
      //   //   value={params?.row?.payment_status ?? ""}
      //   //   handleChange={(event, value, reason) =>
      //   //     updateTreasuryRowFields(params?.row, "payment_status", value.value)
      //   //   }
      //   //   customStyles={{ minWidth: "150px", maxWidth: '200px' }}
      //   //   options={paymentStatus ?? []}
      //   //   getOptionLabel={(option) => option.label}
      //   // />
      // ),
    },
    {
      field: "wire_ref",
      headerName: "Wire #",
      headerAlign: "center",
      width: 160,
      // renderCell: (params) => (
      //   <div>
      //     <Input
      //       name="wire_no"
      //       type="text"
      //       onChange={(event) => updateTreasuryRowFields(params?.row, "wire_no", event.target.value)}
      //       value={params?.row?.wire_no ?? ""}
      //     />
      //   </div>
      // ),
    },
  ];

  const commissionPaymentColumns = [
    {
      headerName: "",
      headerAlign: "center",
      flex: 0.1,
      type: "actions",
      renderCell: renderSelectionCell,
    },
    {
      field: "coverholder_name",
      headerName: "Coverholder Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_account",
      headerName: "Final Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_name",
      headerName: "Final Bank Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account_name",
      headerName: "To Bank Account Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account",
      headerName: "To Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "settlement_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "sum_of_net_payment",
      headerName: "Amount to be transferred",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      headerAlign: "center",
      width: 160,
      renderCell: (params) => (
        <div>
          <Input
            name="payment_date"
            onChange={(event) => updateTreasuryRowFields(params?.row, "payment_date", event.target.value)}
            value={
              params?.row?.payment_date?.substring(0, 10) ?? ""
            }
            type="date"
          />
        </div>
      ),
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      headerAlign: "center",
      width: 180,
      renderCell: (params) => (
        <Input
            name="payment_status"
            type="text"
            onChange={(event) => updateTreasuryRowFields(params?.row, "payment_status", event.target.value)}
            value={params?.row?.payment_status ?? ""}
          />
        // <CommonSelect
        //   placeholder="Payment Status"
        //   value={params?.row?.payment_status ?? ""}
        //   handleChange={(event, value, reason) =>
        //     updateTreasuryRowFields(params?.row, "payment_status", value.value)
        //   }
        //   customStyles={{ minWidth: "150px", maxWidth: '200px' }}
        //   options={paymentStatus ?? []}
        //   getOptionLabel={(option) => option.label}
        // />
      ),
    },
    {
      field: "wire_ref",
      headerName: "Wire #",
      headerAlign: "center",
      width: 160,
      renderCell: (params) => (
        <div>
          <Input
            name="wire_ref"
            type="text"
            onChange={(event) => updateTreasuryRowFields(params?.row, "wire_ref", event.target.value)}
            value={params?.row?.wire_ref ?? ""}
          />
        </div>
      ),
    },
  ];

  const rebatePaymentColumns = [
    {
      headerName: "",
      headerAlign: "center",
      flex: 0.1,
      type: "actions",
      renderCell: renderSelectionCell,
    },
    {
      field: "coverholder_name",
      headerName: "Coverholder Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_account",
      headerName: "Final Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_name",
      headerName: "Final Bank Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account_name",
      headerName: "To Bank Account Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account",
      headerName: "To Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "settlement_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "sum_of_rebate",
      headerName: "Amount to be transferred",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      headerAlign: "center",
      width: 160,
      renderCell: (params) => (
        <div>
          <Input
            name="payment_date"
            onChange={(event) => updateTreasuryRowFields(params?.row, "payment_date", event.target.value)}
            value={
              params?.row?.payment_date?.substring(0, 10) ?? ""
            }
            type="date"
          />
        </div>
      ),
    },
    {
      field: "payment_complete_status",
      headerName: "Payment Status",
      headerAlign: "center",
      width: 180,
      renderCell: (params) => (
        <Input
            name="payment_status"
            type="text"
            onChange={(event) => updateTreasuryRowFields(params?.row, "payment_status", event.target.value)}
            value={params?.row?.payment_status ?? ""}
          />
        // <CommonSelect
        //   placeholder="Payment Status"
        //   value={params?.row?.payment_status ?? ""}
        //   handleChange={(event, value, reason) =>
        //     updateTreasuryRowFields(params?.row, "payment_status", value.value)
        //   }
        //   customStyles={{ minWidth: "150px", maxWidth: '200px' }}
        //   options={paymentStatus ?? []}
        //   getOptionLabel={(option) => option.label}
        // />
      ),
    },
    {
      field: "wire_ref",
      headerName: "Wire #",
      headerAlign: "center",
      width: 160,
      renderCell: (params) => (
        <div>
          <Input
            name="wire_ref"
            type="text"
            onChange={(event) => updateTreasuryRowFields(params?.row, "wire_ref", event.target.value)}
            value={params?.row?.wire_ref ?? ""}
          />
        </div>
      ),
    },
  ];

  const partnerPaymentColumns = [
    {
      headerName: "",
      headerAlign: "center",
      flex: 0.1,
      type: "actions",
      renderCell: renderSelectionCell,
    },
    {
      field: "coverholder_name",
      headerName: "Coverholder Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_account",
      headerName: "Final Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_name",
      headerName: "Final Bank Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account_name",
      headerName: "To Bank Account Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account",
      headerName: "To Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "settlement_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "sum_of_net_payment",
      headerName: "Sum of Final Net Premium",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "sum_of_rebate",
      headerName: "Rebate",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "sum_of_net_payment",
      headerName: "Sum of Net Payment",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      headerAlign: "center",
      width: 160,
      renderCell: (params) => (
        <div>
          <Input
            name="payment_date"
            onChange={(event) => updateTreasuryRowFields(params?.row, "payment_date", event.target.value)}
            value={
              params?.row?.payment_date?.substring(0, 10) ?? ""
            }
            type="date"
          />
        </div>
      ),
    },
    {
      field: "payment_complete_status",
      headerName: "Payment Status",
      headerAlign: "center",
      width: 180,
      renderCell: (params) => (
        <Input
            name="payment_status"
            type="text"
            onChange={(event) => updateTreasuryRowFields(params?.row, "payment_status", event.target.value)}
            value={params?.row?.payment_status ?? ""}
          />
        // <CommonSelect
        //   placeholder="Payment Status"
        //   value={params?.row?.payment_status ?? ""}
        //   handleChange={(event, value, reason) =>
        //     updateTreasuryRowFields(params?.row, "payment_status", value.value)
        //   }
        //   customStyles={{ minWidth: "150px", maxWidth: '200px' }}
        //   options={paymentStatus ?? []}
        //   getOptionLabel={(option) => option.label}
        // />
      ),
    },
    {
      field: "wire_ref",
      headerName: "Wire #",
      headerAlign: "center",
      width: 160,
      renderCell: (params) => (
        <div>
          <Input
            name="wire_ref"
            type="text"
            onChange={(event) => updateTreasuryRowFields(params?.row, "wire_ref", event.target.value)}
            value={params?.row?.wire_ref ?? ""}
          />
        </div>
      ),
    },
  ];

  const getColumnsByPaymentType = () => {
    let treasuryPaymentTypes = [];
    switch (searchFieldParams.type_of_payment) {
      case "Syndicate Payment":
        treasuryPaymentTypes = [...syndicatePaymentColumns];
        break;
      case "Internal Broking":
        treasuryPaymentTypes = [...partnerPaymentColumns];
        break;
      case "MGA Commission":
        treasuryPaymentTypes = [...commissionPaymentColumns];
        break;
      case "Rebate":
        treasuryPaymentTypes = [...rebatePaymentColumns];
        break;
      default:
        treasuryPaymentTypes = [...syndicatePaymentColumns];
        break;
    }
    return treasuryPaymentTypes;
  };

  const handleFieldsInputChange = (name, value, reason) => {
    if (!value) {
      handleClearSearchList();
    }
    setSearchFieldsParams({
      ...searchFieldParams,
      [name]: value?.trim(),
    });
  };

  useEffect(() => {
    setPaginationCount(getPaginationCount(pageState?.total, pageState?.pageSize));
  }, [pageState?.pageSize, pageState?.total]);

  // UseEffect
  useEffect(() => {
    if (isClearPerformed) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClearPerformed]);

  useEffect(() => {
    if (!isClearPerformed) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState?.pageSize, pageState?.page]);

  useEffect(() => {
    getPaymentIds();
  }, []);

  return (
    <div style={Container}>
      <span
        style={{
          color: "#FF5A01",
          fontSize: "20px",
          fontWeight: "400",
          marginTop: 5,
        }}
      >
        Coversheet
      </span>

      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          mb: "5px",
        }}
        container
        xs={12}
        gap={"10px"}
      >
        <Grid item xs={12} md={2} paddingRight={1}>
          <FormLabel>Payment Type</FormLabel>
          <CommonSelect
            placeholder="Select Payment Type"
            value={searchFieldParams?.type_of_payment}
            handleChange={(event, value, reason) =>
              handleSelectDropdown("type_of_payment", value, reason, event)
            }
            options={paymentTypes?.map((option) => option) || []}
          />
        </Grid>

        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          gap={"2px"}
          xs={12}
          md={2}
        >
          <FormLabel>Policy No</FormLabel>
          <TextField
            value={searchFieldParams?.policy_no}
            onChange={(e) =>
              handleFieldsInputChange("policy_no", e.target.value)
            }
            size="small"
          />
        </Grid>

        <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          gap={"2px"}
          xs={12}
          md={2}
        >
          <FormLabel>BDX File Name</FormLabel>
          <TextField
            value={searchFieldParams?.bdx_file_name}
            onChange={(e) =>
              handleFieldsInputChange("bdx_file_name", e.target.value)
            }
            size="small"
          />
        </Grid>

        <Grid item xs={12} md={2} paddingRight={1}>
          <FormLabel>Payment ID</FormLabel>
          <CommonSelect
            placeholder="Select Payment Id"
            value={searchFieldParams?.payment_id}
            handleChange={(event, value, reason) =>
              handleSelectDropdown("payment_id", value, reason, event)
            }
            options={paymentIds?.map((option) => option) || []}
          />
        </Grid>

        <Grid
          container
          // xs={2.8}
          display={"flex"}
          flexDirection={"row"}
          gap={"6px"}
          mt={"20px"}
        >
          <Grid
            item
            ml={"15px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"2px"}
          >
            <FormLabel></FormLabel>
            <CommonButton
              text="Search"
              className="bankStatementBtn"
              handleClick={() => loadSearchTxnData()}
              hoverColor="#EE3F00"
              // disabled={pageState?.isLoading || isFilterBtnDisable()}
            />
          </Grid>
          <Grid
            item
            ml={"15px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"2px"}
          >
            <FormLabel></FormLabel>
            <CommonButton
              text="Clear"
              handleClick={handleClearSearchList}
              className="bankStatementBtn"
              hoverColor="#EE3F00"
              // disabled={pageState?.isLoading || isFilterBtnDisable()}
            />
          </Grid>
          <Grid
            item
            ml={"15px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"2px"}
          >
            <FormLabel></FormLabel>
            <CommonButton
            style={{textWrapMode: "nowrap"}}
              text="Payment Confirm"
              className="bankStatementInitiateBtn"
              hoverColor="#EE3F00"
              disabled={!selectedRecord}
              handleClick={handleReleasePaymentClick}
            />
          </Grid>
        </Grid>
      </Grid>

      <div
        style={{
          height: "calc(100vh - 293px)",
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          className={classes.pagination}
          loading={pageState?.isLoading}
          rows={pageState?.data ?? []}
          rowCount={pageState?.total}
          getRowId={(row) => row?.id}
          columns={getColumnsByPaymentType()}
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          pagination
          paginationMode="server"
          disableSelectionOnClick
          page={pageState.page - 1}
          pageSize={pageState?.pageSize ?? 25}
          onPageSizeChange={(newPageSize) => {
            return setPageState((old) => {
              setPaginationCount(Math.floor(pageState.total / newPageSize));
              return { ...old, pageSize: newPageSize, page: 1 };
            });
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          sx={{
            "& .MuiDataGrid-columnHeader[data-field='File_Name']": {
              minWidth: toggle?.isOpen
                ? "170.137px !important"
                : "198px !important",
            },
          }}
          components={{
            Toolbar: GridToolbarContainer,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Uploaded Files
              </Stack>
            ),
          }}
        />
        <Stack
          style={{
            position: "absolute",
            bottom: "8px",
          }}
        >
          <CommonPaginationContainer
            count={paginationCount + 1}
            pageState={pageState}
            setPageState={setPageState}
          />
        </Stack>
      </div>
      <Dialog open={openReleaseModal} onClose={handleCloseReleaseModal}>
        <DialogTitle>Release Payment</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <FormLabel>Payment Status</FormLabel>
              <CommonSelect
                placeholder="Select Payment Status"
                value={releasePaymentData.payment_complete_status}
                handleChange={(event, value) =>
                  setReleasePaymentData(prev => ({ ...prev, payment_complete_status: value.value }))
                }
                options={paymentStatus}
                getOptionLabel={(option) => option.label}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Wire #</FormLabel>
              <TextField
                fullWidth
                value={releasePaymentData.wire_ref}
                onChange={(e) =>
                  setReleasePaymentData(prev => ({ ...prev, wire_ref: e.target.value }))
                }
                size="small"
                disabled={releasePaymentData.payment_complete_status != 'paid'}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Payment Date</FormLabel>
              <TextField
                fullWidth
                type="date"
                value={releasePaymentData.payment_date}
                onChange={(e) =>
                  setReleasePaymentData(prev => ({ ...prev, payment_date: e.target.value }))
                }
                size="small"
                disabled={releasePaymentData.payment_complete_status != 'paid'}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CommonButton
            text="Cancel"
            handleClick={handleCloseReleaseModal}
            hoverColor="#EE3F00"
          />
          <CommonButton
            text="Submit"
            handleClick={handleReleasePaymentSubmit}
            hoverColor="#EE3F00"
            disabled={isButtonEnable}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CoversheetList;
