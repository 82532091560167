import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Stack, Grid, Checkbox, FormLabel, TextField, Select, MenuItem, ListItemText } from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./paymentUpdatesContainerStyles";
import { CommonPaginationContainer, CommonSelect } from "../common";
import { CommonButton } from "../common/commonButton";
import instance from "../../redux/api";
import { 
  getPayoutSummary,
  Broker,
  SCMTableMaintainance,
  BindingAgreements,
  UniqueCurrencyList,
  CurrencyList, 
} from "../../Services/api";
import { getPaginationCount } from "../CashAllocation/utils";
// import { paymentPayoutSummary, setIds } from "../../redux/slice/PayoutSummarySlice";
import { numberFormatter } from '../../utils/generalFunctions'

export const selectStyleObj = {
  border: "1px solid #ccc",
  padding: 2,
  paddingLeft: 16,
  fontSize: 13,
  width: "100%",
  height: 38,
  borderRadius: 5,
};

const PayoutSummary = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const policyNo = new URLSearchParams(search).get("policyNo");
  const fileName = new URLSearchParams(search).get("fileName");
  const paymentType = new URLSearchParams(search).get("paymentType");
  const scmType = new URLSearchParams(search).get("scmType");
  const category = new URLSearchParams(search).get("category");
  const partner = new URLSearchParams(search).get("partner");
  const placing_broker = new URLSearchParams(search).get("placing_broker");
  const overall_status = new URLSearchParams(search).get("overall_status");
  

  const [paginationCount, setPaginationCount] = useState(0);
  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [isClearPerformed, setIsClearPerformed] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState([]);

  const [searchFieldParams, setSearchFieldsParams] = useState({
    type_of_payment: paymentType,
    scm_type: scmType,
    category: category,
    policy_no: policyNo,
    bdx_file_name: fileName ?? "",
    payment_id: "",
    partner: "",
    placing_broker: "",
    overall_status: [],
    final_bank:"",
    settlement_currency:"",
  });

  const toggle = useSelector((state) => state.toggleSideMenu);

  const currentSkipNumber = () => {
    return pageState?.page === 1
    ? 0
    : pageState?.page === 0
    ? pageState?.page
    : pageState?.page - 1;
  };

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });
  
  const [overallStatuses, setOverallStatuses] = useState([]);
  const [brokerData, setBrokerData] = useState([]);
  const [partnerData, setPartnerData] = useState([]);  
  const [bindingAgreementData, setbindingAgreementData] = useState([]);
  const [finalBanks, setFinalBanks] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  //styles
  const classes = useStyles();

  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  const paymentTypes = ["Syndicate Payment", "Internal Broking", "MGA Commission", "Rebate"];
  const SCMTypes = ["SCM", "Non SCM"];
  const categories = ['Cash', "Non Cash"];

  const loadBrokers = async () => {
    try {
    const response = await instance.get(`${Broker}`)
      if (response.status === 200) {
        setBrokerData(response?.data ?? []);
      } else {
        setBrokerData([]);
      }
    } catch (error) {
      console.error('API call failed:', error);
    }
    
  }

  const loadPartners = async () => {
    try {
    const response = await instance.get(`${SCMTableMaintainance}`)
      if (response.status === 200) {
        setPartnerData(response?.data ?? []);
      } else {
        setPartnerData([]);
      }
    } catch (error) {
      console.error('API call failed:', error);
    }
    
  }

  const fetchBindingAgreementData = async () => {
    try {
      const response = await instance.get(BindingAgreements);
      if (response.status === 200) {
        
        setbindingAgreementData(response.data);
      } else {
        setbindingAgreementData([]);
      }
    } catch (error) {
      console.error('API call failed:', error);
    }
  };

  const fetchFinalBanks = async () => {
    try {
      const response = await instance.get(UniqueCurrencyList);
      if (response.status === 200) {
        setFinalBanks(response.data.bank_names);
      } else {
        setFinalBanks([]);
      }
    } catch (error) {
      console.error('API call failed:', error);
    }
  };

  const fetchSettlementCurrencies = async () => {
    try {
      const response = await instance.get(CurrencyList);
      if (response.status === 200) {
        setCurrencies(response.data);
      } else {
        setCurrencies([]);
      }
    } catch (error) {
      console.error('API call failed:', error);
    }
  };


  const searchQueryParams = () => {
    let getsearchQueryParams = "";

    const { type_of_payment, policy_no, bdx_file_name, payment_id, category, scm_type, placing_broker, overall_status, partner,final_bank, settlement_currency } = searchFieldParams;

    if (type_of_payment) {
      getsearchQueryParams = `&type_of_payment=${type_of_payment}`
    }

    if (policy_no) {
      getsearchQueryParams = `${getsearchQueryParams}&certificate_ref=${policy_no}`;
    }

    if (bdx_file_name) {
      getsearchQueryParams = `${getsearchQueryParams}&file_name=${bdx_file_name}`;
    }

    if (payment_id) {
      getsearchQueryParams = `${getsearchQueryParams}&payment_id=${payment_id}`
    }

    if (category) {
      getsearchQueryParams = `${getsearchQueryParams}&category=${category}`
    }

    if (scm_type) {
      getsearchQueryParams = `${getsearchQueryParams}&scm_type=${scm_type}`
    }

    if (placing_broker) {
      getsearchQueryParams = `${getsearchQueryParams}&placing_broker=${placing_broker}`
    }

    if (partner) {
      getsearchQueryParams = `${getsearchQueryParams}&partner=${partner}`
    }

    if (settlement_currency) {
      getsearchQueryParams = `${getsearchQueryParams}&settlement_currency=${settlement_currency}`
    }

    if (final_bank) {
      getsearchQueryParams = `${getsearchQueryParams}&final_bank=${final_bank}`
    }

    if (overall_status?.length) {
      const overallStatusStr = overall_status.join(",");
      getsearchQueryParams = `${getsearchQueryParams}&overall_status=${overallStatusStr}`;
    }

    return getsearchQueryParams;
  }

  const loadSearchTxnData = async () => {
    try {
      let queryParams = `skip=0&pageSize=${pageState?.pageSize}`;

      const getSearchQueryParam = searchQueryParams()
      if (getSearchQueryParam) {
        queryParams = `${queryParams}${getSearchQueryParam}`
      }

      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const response = await instance.get(`${getPayoutSummary}?${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      setIsSearchPerformed(true);
      setIsClearPerformed(false);

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.total,
          data: response?.data?.data ?? [],
        }));
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count ?? 0,
          data: [],
          page: 1,  // required for sending skip as 0
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));
      console.log("err", err);
    }
  };

  const loadData = async () => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      let queryParams = `skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize}`;

      if (isSearchPerformed) {
        const getSearchQueryParam = searchQueryParams();
        if (getSearchQueryParam) {
          queryParams = `${queryParams}${getSearchQueryParam}`;
        }
      } else {
        // If we get information while performing route navigation
        if (!isClearPerformed) {
          if (policyNo) {
            queryParams = `${queryParams}&certificate_ref=${policyNo}`;
            setSearchFieldsParams({ ...searchFieldParams, policy_no: policyNo })
          }

          if (fileName) {
            queryParams = `${queryParams}&file_name=${fileName}`;
            setSearchFieldsParams({ ...searchFieldParams, bdx_file_name: fileName });
          }

          if (paymentType) {
            queryParams = `${queryParams}&type_of_payment=${paymentType}`;
            setSearchFieldsParams({ ...searchFieldParams, type_of_payment: paymentType });
          }
        }
      }

      const response = await instance.get(`${getPayoutSummary}?${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.total,
          data: response?.data.data ?? [],
        }));
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.error("err", err);
    }
  };

  const handleSelectDropdown = (name, value, reason) => {
    if (reason === "clear") {
      loadData();
    }
    setSearchFieldsParams({
      ...searchFieldParams,
      [name]: value ?? "",
    });
  };

  const handleClearSearchList = async () => {
    setIsSearchPerformed(false);
    setIsClearPerformed(true);

    // clear fields
    setSearchFieldsParams({
      type_of_payment: "",
      policy_no: "",
      bdx_file_name: "",
      payment_id: "",
      category: "",
      overall_status: [],
      partner: "",
      placing_broker: "",
      scm_type: "",
    });
  };

  // const { selectedCheckboxIds, data, isLoading, error } = useSelector((state) => state?.PayoutSummary);

  const handleRadioChange = (e) => {
    const { value, checked } = e.target
    
    if(checked)
      setSelectedRecords([...selectedRecords, Number(value)])

    else
      setSelectedRecords([...selectedRecords.filter(record => record !== Number(value))])
  };

  const handleInitiatePaymentClick = () => {  
    // dispatch(paymentPayoutSummary(selectedCheckboxIds));
    const response = instance.post(`${getPayoutSummary}`,{ids: selectedRecords})
    .then((response) => {
      handleClearSearchList();
    })
    .catch((error) => console.error("Error : ", error))
  };

  const partnerPaymentColumns = [
    {
      headerName: "",
      headerAlign: "center",
      flex: 0.1,
      type: "actions",
      renderCell: (params) => (
        <>
          <Checkbox
            // checked={selectedCheckboxIds?.includes(params?.row?.id)}
            onChange={(e) => handleRadioChange(e)}
            value={params?.row?.id}
            name="file-selection"
            style={{ color: "black" }}
          />
        </>
      ),
    },
    {
      field: "bdx_file_name",
      headerName: "BDX File Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "coverholder_name",
      headerName: "Cover Holder Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_id",
      headerName: "Payment ID",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "partner_name",
      headerName: "Partner Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_type",
      headerName: "Payment Type",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_account",
      headerName: "Final Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_name",
      headerName: "Final Bank Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account_name",
      headerName: "To Bank Account Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account",
      headerName: "To Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "settlement_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "sum_of_final_net_premium_settlement_currency",
      headerName: "Sum of Final Net Premium Settlement Currency",
      headerAlign: "center",
      valueFormatter: (params) => {
        return numberFormatter(params.value);
      },
      width: 160,
    },
    {
      field: "sum_of_rebate",
      headerName: "Sum of Rebate",
      headerAlign: "center",
      valueFormatter: (params) => {
        return numberFormatter(params.value);
      },
      width: 160,
    },
    {
      field: "sum_of_net_payment",
      headerName: "Sum of Net Payment",
      headerAlign: "center",
      valueFormatter: (params) => {
        return numberFormatter(params.value);
      },
      width: 160,
    },

  ];

  const syndicatePaymentColumns = [
    {
      headerName: "",
      headerAlign: "center",
      flex: 0.1,
      type: "actions",
      renderCell: (params) => (
        <>
          <Checkbox
            // checked={selectedCheckboxIds?.includes(params?.row?.id)}
            onChange={(e) => handleRadioChange(e)}
            value={params?.row?.id}
            name="file-selection"
            style={{ color: "black" }}
          />
        </>
      ),
    },
    {
      field: "bdx_file_name",
      headerName: "BDX File Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "coverholder_name",
      headerName: "Cover Holder Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_id",
      headerName: "Payment ID",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "partner_name",
      headerName: "Partner Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_type",
      headerName: "Payment Type",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_account",
      headerName: "Final Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_name",
      headerName: "Final Bank Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account_name",
      headerName: "To Bank Account Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account",
      headerName: "To Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "settlement_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
    // {
    
    //   field: "sum_of_final_net_premium_settlement_currency",
    //   headerName: "Sum of Final Net Premium Settlement Currency",
    //   headerAlign: "center",
    //   valueFormatter: (params) => {
    //     return numberFormatter(params.value);
    //   },
    //   width: 160,
    // },
    // {
    //   field: "sum_of_rebate",
    //   headerName: "Sum of Rebate",
    //   headerAlign: "center",
    //   valueFormatter: (params) => {
    //     return numberFormatter(params.value);
    //   },
    //   width: 160,
    // },
    {
      field: "sum_of_net_payment",
      headerName: "Sum of Net Payment",
      headerAlign: "center",
      valueFormatter: (params) => {
        return numberFormatter(params.value);
      },
      width: 160,
    },
  ];

  const commissionPaymentColumns = [
    {
      headerName: "",
      headerAlign: "center",
      flex: 0.1,
      type: "actions",
      renderCell: (params) => (
        <>
          <Checkbox
            // checked={selectedCheckboxIds?.includes(params?.row?.id)}
            onChange={(e) => handleRadioChange(e)}
            value={params?.row?.id}
            name="file-selection"
            style={{ color: "black" }}
          />
        </>
      ),
    },
    {
      field: "bdx_file_name",
      headerName: "BDX File Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "coverholder_name",
      headerName: "Cover Holder Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_id",
      headerName: "Payment ID",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "partner_name",
      headerName: "Partner Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_type",
      headerName: "Payment Type",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_account",
      headerName: "Final Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_name",
      headerName: "Final Bank Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account_name",
      headerName: "To Bank Account Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account",
      headerName: "To Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "settlement_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "sum_of_final_net_premium_settlement_currency",
      headerName: "Sum of Final Net Premium Settlement Currency",
      headerAlign: "center",
      valueFormatter: (params) => {
        return numberFormatter(params.value);
      },
      width: 160,
    },
    {
      field: "sum_of_rebate",
      headerName: "Sum of Rebate",
      headerAlign: "center",
      valueFormatter: (params) => {
        return numberFormatter(params.value);
      },
      width: 160,
    },
    {
      field: "sum_of_net_payment",
      headerName: "Sum of Net Payment",
      headerAlign: "center",
      valueFormatter: (params) => {
        return numberFormatter(params.value);
      },
      width: 160,
    },
  ];

  const rebatePaymentColumns = [
    {
      headerName: "",
      headerAlign: "center",
      flex: 0.1,
      type: "actions",
      renderCell: (params) => (
        <>
          <Checkbox
            // checked={selectedCheckboxIds?.includes(params?.row?.id)}
            onChange={(e) => handleRadioChange(e)}
            value={params?.row?.id}
            name="file-selection"
            style={{ color: "black" }}
          />
        </>
      ),
    },
    {
      field: "bdx_file_name",
      headerName: "BDX File Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "coverholder_name",
      headerName: "Cover Holder Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_id",
      headerName: "Payment ID",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "partner_name",
      headerName: "Partner Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "payment_type",
      headerName: "Payment Type",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_account",
      headerName: "Final Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "final_bank_name",
      headerName: "Final Bank Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account_name",
      headerName: "To Bank Account Name",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "to_bank_account",
      headerName: "To Bank Account",
      headerAlign: "center",
      width: 160,
    },
    {
      field: "settlement_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      width: 160,
    },
    // {
    //   field: "sum_of_final_net_premium_settlement_currency",
    //   headerName: "Sum of Final Net Premium Settlement Currency",
    //   headerAlign: "center",
    //   valueFormatter: (params) => {
    //     return numberFormatter(params.value);
    //   },
    //   width: 160,
    // },
    {
      field: "sum_of_rebate",
      headerName: "Sum of Rebate",
      headerAlign: "center",
      valueFormatter: (params) => {
        return numberFormatter(params.value);
      },
      width: 160,
    },
    // {
    //   field: "sum_of_net_payment",
    //   headerName: "Sum of Net Payment",
    //   headerAlign: "center",
    //   valueFormatter: (params) => {
    //     return numberFormatter(params.value);
    //   },
    //   width: 160,
    // },
  ];


  const getColumnsByPaymentType = () => {
    let treasuryPaymentTypes = [];
    switch (searchFieldParams.type_of_payment) {
      case "Syndicate Payment":
        treasuryPaymentTypes = [...syndicatePaymentColumns];
        break;
      case "Internal Broking":
        treasuryPaymentTypes = [...partnerPaymentColumns];
        break;
      case "MGA Commission":
        treasuryPaymentTypes = [...commissionPaymentColumns]
        break;
      case "Rebate":
        treasuryPaymentTypes = [...rebatePaymentColumns]
        break;
      default:
        treasuryPaymentTypes = [...syndicatePaymentColumns];
        break;
    }

    return treasuryPaymentTypes
  }

  const handleFieldsInputChange = (name, value, reason) => {
    if (!value) {
      handleClearSearchList()
    }
    setSearchFieldsParams({
      ...searchFieldParams,
      [name]: value?.trim(),
    });
  };

  const getOverallStatusList = async () => {
    const response = await instance.get(
      "payment/payment_overall_status/overall_status/"
    );
    const overall_status_list = response.data.data;
    setOverallStatuses(overall_status_list);
  };

  useEffect(() => {
    setPaginationCount(getPaginationCount(pageState?.total, pageState?.pageSize));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState?.total]);

  // UseEffect
  useEffect(() => {
    if (isClearPerformed) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClearPerformed]);

  useEffect(() => {
    if (!isClearPerformed) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState?.pageSize, pageState?.page]);

  useEffect(() => {
    // getOverallStatusList();
    // loadPartners();
    // fetchBindingAgreementData();
    fetchFinalBanks();
    fetchSettlementCurrencies();
    loadBrokers();
  }, [])


  return (
    <div style={Container}>
      <span style={{ color: "#FF5A01", fontSize: "20px", fontWeight: "400", marginTop: 5 }}>
        Payout Summary
      </span>

      {/* Search Fields */}
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          mb: "5px",
        }}
        container
        gap={"10px"}
      >
        <Grid
          item
          xs={12}
          md={3}
          paddingRight={1}
        >
          <FormLabel>Payment Type</FormLabel>
          <CommonSelect
            placeholder="Select Payment Type"
            value={searchFieldParams?.type_of_payment}
            handleChange={(event, value, reason) =>
              handleSelectDropdown(
                "type_of_payment",
                value,
                reason,
                event
              )
            }
            options={paymentTypes?.map((option) => option) || []}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          paddingRight={1}
        >
          <FormLabel>Category</FormLabel>
          <CommonSelect
            placeholder="Select Cash Type"
            value={searchFieldParams?.category}
            handleChange={(event, value, reason) =>
              handleSelectDropdown(
                "category",
                value,
                reason,
                event
              )
            }
            options={categories?.map((option) => option) || []}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          paddingRight={1}
        >
          <FormLabel>Placing Broker</FormLabel>
          <CommonSelect
              placeholder="Select Placing Broker"
              name="placing_broker"
              value={searchFieldParams?.placing_broker}
              handleChange={(event, value, reason) =>
                handleSelectDropdown(
                  "placing_broker",
                  value,
                  reason,
                  event
                )
              }
              options={
                brokerData?.map((option) => option?.broker_name) ||
                []
              }
            />
          
        </Grid>
        {/* <Grid
          item
          xs={12}
          md={3}
          paddingRight={1}
        >
          <FormLabel>SCM Partner</FormLabel>
          <CommonSelect
              placeholder="Select Partner"
              name="partner"
              value={searchFieldParams?.partner}
              handleChange={(event, value, reason) =>
                handleSelectDropdown(
                  "partner",
                  value,
                  reason,
                  event
                )
              }
              options={
                partnerData?.map((option) => option?.partner_name) ||
                []
              }
            />
          
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          paddingRight={1}
        >
          <FormLabel>Overall Status</FormLabel>
            <Select
                style={{ ...selectStyleObj }}
                name="overall_status"
                labelId=""
                id=""
                multiple
                placeholder="Select Final Include"
                value={searchFieldParams.overall_status}
                onChange={(event) =>
                  handleSelectDropdown(
                    "overall_status",
                    event.target.value,
                    "",
                    event
                  )
                }
                renderValue={(selected) => selected.join(",").substr(0, 30)} //value joined as an array
              >
                {overallStatuses.map((name,index) => (
                  <MenuItem key={index} value={name}>
                    <Checkbox
                      checked={
                        searchFieldParams?.overall_status?.indexOf(name) > -1
                      }
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
            </Select>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          paddingRight={1}
        >
          <FormLabel>SCM Type</FormLabel>
          <CommonSelect
              placeholder="Select SCM Type"
              name="SCM_Type"
              value={searchFieldParams?.scm_type}
              handleChange={(event, value, reason) =>
                handleSelectDropdown(
                  "scm_type",
                  value,
                  reason,
                  event
                )
              }
              options={
                bindingAgreementData?.map((option) => option?.binding_aggrement_type) ||
                []
              }
            />
        </Grid> */}
        <Grid
          item
          xs={12}
          md={3}
          paddingRight={1}
        >
          <FormLabel>Final Bank</FormLabel>
          <CommonSelect
              placeholder="Select Final Bank"
              name="final_bank"
              value={searchFieldParams?.final_bank}
              handleChange={(event, value, reason) =>
                handleSelectDropdown(
                  "final_bank",
                  value,
                  reason,
                  event
                )
              }
              options={finalBanks ||[]}
            />
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          paddingRight={1}
        >
          <FormLabel>Settlement Currency</FormLabel>
          <CommonSelect
              placeholder="Select Settlement Currency"
              name="settlement_currency"
              value={searchFieldParams?.settlement_currency}
              handleChange={(event, value, reason) =>
                handleSelectDropdown(
                  "settlement_currency",
                  value,
                  reason,
                  event
                )
              }
              options={
                currencies?.map((option) => option?.currency_code) ||
                []
              }
            />
        </Grid>
        {/* <Grid
          item
          display={"flex"}
          flexDirection={"column"}
          gap={"2px"}
          xs={12}
          md={3}
        >
          <FormLabel>BDX File Name</FormLabel>
          <TextField
            value={searchFieldParams?.bdx_file_name}
            onChange={(e) =>
              handleFieldsInputChange(
                "bdx_file_name",
                e.target.value
              )
            }
            size="small"
          />
        </Grid> */}

        {/* <Grid
          item
          xs={12}
          md={2}
          paddingRight={1}
        >
          <FormLabel>Payment ID</FormLabel>
          <CommonSelect
            placeholder="Select Payment Id"
            value={searchFieldParams?.payment_id}
            handleChange={(event, value, reason) =>
              handleSelectDropdown(
                "payment_id",
                value,
                reason,
                event
              )
            }
            options={paymentIds?.map((option) => option) || []}
          />
        </Grid> */}

        <Grid
          container
          display={"flex"}
          flexDirection={"row"}
          gap={"6px"}
          mt={"20px"}
        >
          <Grid
            item
            mt={"17px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"2px"}
          >
            <FormLabel></FormLabel>
            <CommonButton
              text="Search"
              className="bankStatementBtn"
              handleClick={() => loadSearchTxnData()}
              hoverColor="#EE3F00"
            // disabled={pageState?.isLoading || isFilterBtnDisable()}
            />
          </Grid>
          <Grid
            item
            mt={"17px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"2px"}
          >
            <FormLabel></FormLabel>
            <CommonButton
              text="Clear"
              handleClick={handleClearSearchList}
              className="bankStatementBtn"
              hoverColor="#EE3F00"
            // disabled={pageState?.isLoading || isFilterBtnDisable()}
            />
          </Grid>
          <Grid
            item
            mt={"17px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"2px"}
          >
            <FormLabel></FormLabel>
            <CommonButton
              text="Initiate Payment"
              className="bankStatementInitiateBtn"
              hoverColor="#EE3F00"
              disabled={selectedRecords?.length == 0}
              handleClick={handleInitiatePaymentClick}
            />
          </Grid>
        </Grid>
      </Grid>

      <div
        style={{
          height: "calc(100vh - 293px)",
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          className={classes.pagination}
          loading={pageState?.isLoading}
          rows={pageState?.data ?? []}
          rowCount={pageState?.total}
          getRowId={(row) => row?.id}
          columns={getColumnsByPaymentType()}
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          pagination
          paginationMode="server"
          disableSelectionOnClick
          page={pageState.page - 1}
          pageSize={pageState?.pageSize ?? 25}
          onPageSizeChange={(newPageSize) => {
            return setPageState((old) => {
              setPaginationCount(getPaginationCount(pageState.total / newPageSize));
              return { ...old, pageSize: newPageSize, page: 1 };
            });
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          sx={{
            "& .MuiDataGrid-columnHeader[data-field='File_Name']": {
              minWidth: toggle?.isOpen
                ? "170.137px !important"
                : "198px !important",
            },
          }}
          components={{
            Toolbar: GridToolbarContainer,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Uploaded Files
              </Stack>
            ),
          }}
        />
        <Stack
          style={{
            position: "absolute",
            bottom: "8px",
          }}
        >
          <CommonPaginationContainer
            count={paginationCount + 1}
            pageState={pageState}
            setPageState={setPageState}
          />
        </Stack>
      </div>
    </div>
  );
};

export default PayoutSummary;
