import { Grid, Tooltip } from "@mui/material";
import "./bdx.css";

function handleAmount(val) {
  const options = {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return (
    (Number(val) && Number(val).toLocaleString("en-US", options)) ||
    (val === 0 && parseInt(val).toFixed(2)) ||
    val
  );
}

function handlePercentage(val) {
  const options = {
    style: "decimal",
    minimumFractionDigits: 8,
    maximumFractionDigits: 8,
  };

  return (
    (Number(val) && val.toLocaleString("en-US", options)) ||
    (val === 0 && Number(val).toFixed(8)) ||
    val
  );
}
const ExceptionReportDetails = ({ record }) => {
  return (
    <div className="exception-details">
      <Grid container xs={12} spacing={2}>
        <Grid item md={2.2} paddingLeft={2}>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              YOA
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.yoa || ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              UMR
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.umr || ""}
            </Grid>
          </Grid>

          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Yr Month
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.yr_month || ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              CT Status
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.ct_status || ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Allocation Date
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.allocation_date || ""}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2.4} paddingLeft={4}>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              BDx Gross Premium
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.bdx_gross_premium ? handleAmount(record?.bdx_gross_premium) : ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              BDx Brokerage
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.bdx_brokerage ? handleAmount(record?.bdx_brokerage) : ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Taxes
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.tax ? handleAmount(record?.tax) : ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              % for Lloyd's
            </Grid>
            <Grid item fontWeight={"bold"}>
              {handlePercentage(record?.percentage_for_lloyd) || ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Lloyds Market
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.lloyds_market || ""}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2.6} paddingLeft={4}>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Receivable Amount
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.receivable_amount ? handleAmount(record?.receivable_amount) : ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Bank Charges
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.bank_charges ? handleAmount(record?.bank_charges) : ""}
            </Grid>
          </Grid>

          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              CT Reconiliation to BDX Comments
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.ct_reconiliation_to_bdx_comments || ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Treasury Policy Level Payment Verification
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.treasury_policy_level_payment_verification ? handleAmount(record?.treasury_policy_level_payment_verification) : ""}
            </Grid>
          </Grid>

          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Cash Bank Ref
            </Grid>
            <Grid item fontWeight={"bold"}>
              <Tooltip title={record?.cash_bank_ref}>
                {record?.cash_bank_ref?.substr(0, 20) || ""}
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2.4} paddingLeft={4}>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Cash Payment Date
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.cash_payment_date || ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              1609 Bank Ref
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.sixteen_hundred_nine_bank_ref || ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              1609 Payment Date
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.sixteen_hundred_nine_payment_date || ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Commision
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.commision ? handleAmount(record?.commision) : ""}
            </Grid>
          </Grid>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Commision Bank Ref
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.commision_bank_ref || ""}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2.4} paddingLeft={4}>
          <Grid marginBottom={1} marginTop={1}>
            <Grid item color={"#555"} fontWeight={"medium"}>
              Commision Payment Date
            </Grid>
            <Grid item fontWeight={"bold"}>
              {record?.commision_payment_date || ""}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ExceptionReportDetails;
