import React, { useState, useEffect } from 'react';
import { Grid} from '@mui/material';
import '../../../assets/scss/autoComplete.scss';
import { ValidatorForm} from 'react-material-ui-form-validator';
import format from 'date-fns/format';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { getUsersList } from '../../../redux/slice/usersListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Edit, View } from '../../../assets/icons';
import Switch from './switch';
import SwitchStatusDialog from './switchStatusDialog';
import ROUTES from '../../../utils/routes';
import { ROLES } from '../../../utils/config';
import { updateUserData } from '../../../redux/slice/manageUserSlice';
import { CommonButton } from '../../../Components/common/commonButton';
import { CommonSelect } from '../../../Components/common';

const userListaddUser = {
  color: 'white',
  backgroundColor: '#FF5A01',
  borderRadius: '20px',
  marginRight: '10px',
};


const UserList = () => {
  const { usersList } = useSelector((state) => state?.usersList);
  const { userData } = useSelector((state) => state?.user);
  const isUserListLoading = useSelector(
    (state) => state?.usersList?.isUserListLoading
  );
  const toggle = useSelector((state) => state.toggleSideMenu);

  const container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  const [formdata, setFormData] = useState({
    name: "",
    startdate: "",
    enddate: "",
    status: "",
    role: "",
    email: "",
  });

  const [switchDialog, setSwitchDialog] = useState({
    id: "",
    status: "",
    isOpen: false,
  });
  const [searchResult, setsearchResult] = useState([]);

  // Trigger search logic whenever form data changes
  useEffect(() => {
    handleSubmitSearch();
  }, [formdata, usersList]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsersList());
  }, []);

  const userManagement =
    userData?.user_permissions?.permissions_list["User Management"] ?? {};

  const displayActionsColumn =
    userManagement?.Add?.toUpperCase() === "Y" ||
    userManagement?.Edit?.toUpperCase() === "Y" ||
    userManagement?.View?.toUpperCase() === "Y" ||
    userManagement?.["In-Active"]?.toUpperCase() === "Y";

  const handleSubmitSearch = async () => {
    setsearchResult([]);
    var newSearchArr = [];
    if (formdata.name && formdata.role && formdata.status) {
      usersList.find((opt) => {
        if (
          opt.user_name &&
          opt.user_name.toLowerCase()?.includes(formdata.name?.toLowerCase()) &&
          opt.role &&
          formdata.role === opt.role &&
          opt.status &&
          formdata.status === opt.status
        ) {
          newSearchArr.push(opt);
        }
      });
      if (newSearchArr.length === 0) {
        // setnoSearchResultData(true);
      }
    } else if (formdata.name && formdata.role) {
      usersList.find((opt) => {
        if (
          opt.user_name &&
          opt.user_name.toLowerCase()?.includes(formdata.name?.toLowerCase()) &&
          opt.role &&
          formdata.role === opt.role
        ) {
          newSearchArr.push(opt);
        }
      });
      if (newSearchArr.length === 0) {
        // setnoSearchResultData(true);
      }
    } else if (formdata.name && formdata.status) {
      usersList.find((opt) => {
        if (
          opt.user_name &&
          opt.user_name.toLowerCase()?.includes(formdata.name?.toLowerCase()) &&
          formdata.status === opt.status
        ) {
          newSearchArr.push(opt);
        }
      });
      if (newSearchArr.length === 0) {
        // setnoSearchResultData(true);
      }
    } else if (formdata.role && formdata.status) {
      usersList.find((opt) => {
        if (
          opt.role &&
          formdata.role === opt.role &&
          formdata.status === opt.status
        ) {
          newSearchArr.push(opt);
        }
      });
      if (newSearchArr.length === 0) {
        // setnoSearchResultData(true);
      }
    } else if (formdata.name) {
      usersList.find((opt) => {
        if (
          opt.user_name &&
          opt.user_name.toLowerCase()?.includes(formdata.name?.toLowerCase())
        ) {
          newSearchArr.push(opt);
        }
      });
      if (newSearchArr.length === 0) {
        // setnoSearchResultData(true);
      }
    } else if (formdata.role) {
      usersList.find((opt) => {
        if (opt.role && formdata.role === opt.role) {
          newSearchArr.push(opt);
        }
      });
      if (newSearchArr.length === 0) {
        // setnoSearchResultData(true);
      }
    } else if (formdata.status) {
      usersList.find((opt) => {
        if (formdata.status === opt.status) {
          newSearchArr.push(opt);
        }
      });
      if (newSearchArr.length === 0) {
        // setnoSearchResultData(true);
      }
    } else {
      // setnoSearchResultData(false);
    }
     setsearchResult(newSearchArr);
    // setnoSearchResultData(newSearchArr.length === 0);
  };

  // Calculate the starting and ending indices for the current page
  // const startIndex = page * rowsPerPage;
  // const endIndex = Math.min(startIndex + rowsPerPage, usersList.length);
  // const paginatedData = usersList?.slice(startIndex, endIndex);

  const navigate = useNavigate();

  const handleAddBtnClick = () => {
    navigate(ROUTES.AUMAU);
  };

  let columns = [
    {
      field: "user_name",
      headerName: "User Name",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "division",
      headerName: "Division",
      headerAlign: "center",
      flex: 1,
      valueGetter: ({ row }) => row?.division.entity_divisions,
    },
    {
      field: "role",
      headerName: "Role",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "reporting_manager",
      headerName: "Reporting Manager",
      headerAlign: "center",
      flex: 1,
      valueGetter: ({ row }) => row?.reporting_manager.user_name,
    },
    {
      field: "user_start_date",
      headerName: "User Start Date",
      headerAlign: "center",
      flex: 1,
      valueGetter: ({ row }) =>
        row?.user_start_date
          ? format(new Date(row?.user_start_date), "dd-MM-yyyy")
          : "",
    },
    {
      field: "user_end_date",
      headerName: "User End Date",
      headerAlign: "center",
      flex: 1,
      valueGetter: ({ row }) =>
        row?.user_end_date
          ? format(new Date(row?.user_end_date), "dd-MM-yyyy")
          : "",
    },
  ];

  if (displayActionsColumn) {
    columns.push({
      field: "actions",
      headerAlign: "center",
      flex: 1,
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      getActions: ({ row }) => {
        let actionsList = [];
        if (userManagement?.View?.toUpperCase() === "Y") {
          actionsList.push(
            <GridActionsCellItem
              icon={<View />}
              label="View"
              className="textPrimary"
              onClick={() => {
                navigate(ROUTES.AUMVU);
                dispatch(updateUserData(row));
              }}
              color="inherit"
            />
          );
        }

        if (userManagement?.Edit?.toUpperCase() === "Y") {
          actionsList.push(
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              onClick={() => {
                navigate(ROUTES.AUMEU);
                dispatch(updateUserData(row));
              }}
              color="inherit"
            />
          );
        }

        if (userManagement?.["In-Active"]?.toUpperCase() === "Y") {
          actionsList.push(
            <GridActionsCellItem
              key={row?.status}
              icon={
                <Switch
                  key={row?.id}
                  {...row}
                  inputProps={{ "aria-label": "User Status" }}
                  checked={
                    row?.status === "Active" || row?.status === "Activate"
                  }
                />
              }
              label="Status"
              className="textPrimary"
              onClick={() =>
                setSwitchDialog({
                  id: row?.id,
                  status: row?.status,
                  isOpen: true,
                })
              }
              color="inherit"
            />
          );
        }

        return actionsList;
      },
    });
  }


  const UserListBox = {
    display: "flex",
    justifyContent: "space-between",
    height: 32,
    fontWeight: "400",
    marginTop: 10,
  };

  return (
    <div style={container}>
      <div style={UserListBox}>
        <span style={{ color: "#FF5A01", fontSize: "24px" }}>Users List </span>
        {userManagement?.Add?.toUpperCase() === "Y" && (
          <CommonButton
            text="Add User"
            handleClick={handleAddBtnClick}
            sx={userListaddUser}
            hoverColor="#FF5A01"
          />
        )}
      </div>

      <ValidatorForm onSubmit={handleSubmitSearch} onError={() => null}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={toggle?.isOpen ? 4 : 3}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>Name</span>
              <input
                onChange={(e) =>
                  setFormData({ ...formdata, name: e.target.value })
                }
                className="inputField"
                placeholder="Search..."
                style={{ height: "32px", marginTop: "5px" }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={toggle?.isOpen ? 4 : 3}>
            <div style={{ marginBottom: 4 }}>Role</div>
            <CommonSelect
              name="role"
              value={formdata?.role}
              placeholder="Select Role"
              handleChange={(event, value, reason) =>
                setFormData({ ...formdata, role: value })
              }
              options={ROLES}
            />
          </Grid>
          <Grid item xs={12} md={toggle?.isOpen ? 4 : 3}>
            <div style={{ marginBottom: 4 }}>Status</div>
            <CommonSelect
              name="status"
              value={formdata?.status}
              placeholder="Select Status"
              handleChange={(event, value, reason) =>
                setFormData({ ...formdata, status: value })
              }
              options={["Active", "Inactive"]}
            />
          </Grid>
        </Grid>
      </ValidatorForm>

      <div
        style={{
          height: 600,
        }}
      >
        <DataGrid
          loading={isUserListLoading}
          rows={
            formdata.name || formdata.role || formdata.status
              ? searchResult
              : usersList
          }
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "25", value: 25 },
            { label: "50", value: 50 },
            { label: "100", value: 100 },
          ]}
          autoPageSize={true}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu
        />
      </div>

      <SwitchStatusDialog {...switchDialog} setSwitchDialog={setSwitchDialog} />
    </div>
  );
};

export default UserList;
