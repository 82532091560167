import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "../../assets/scss/breadcrumb.scss";

const container = {
  backgroundColor: "white",
  height: "25px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  padding: "8px 24px 8px 16px",
};

const Breadcrumb = () => {
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");

  const paths = {
    "/Bank-Txns": "/Bank-Txns/Upload-files",
    "/Administration": "/Administration/User-Maintainace",
    "/Reports": "/Reports/Cash-Tracker",
  };

  const getPath = (path, index) => {
    const returnPath = `/${path.slice(0, index + 1).join("/")}`;
    return returnPath || paths[returnPath];
  };

  return (
    <div style={container}>
      {pathSegments.map((segment, index) => (
        <React.Fragment key={index}>
          {index > 0 && <span> / </span>}
          <NavLink
            to={getPath(pathSegments, index)}
            className={index === 0 ? "nav-link" : "activeLinkStyle"}
          >
            {segment}
          </NavLink>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
