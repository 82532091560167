import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API_URL from '../api';

const initialState = {
    allReportsLink: [],
    isLoading: false,
    error: null,
    reportSidebarData: [],
}

export const getAllReportsLinks = createAsyncThunk(
    'allReportsLink/List',
    async (id) => {
        const res = await API_URL.get("documents/powerbi_reports/");
        const data = await res.data;

        // extract the data and sort them based on report_sequence
        const sortedData = data?.sort((a, b) => a.report_sequence - b.report_sequence);

        const processedReportsData = {};
        let reportSidebarData = [];

        sortedData?.forEach(element => {
            const { report_name, report_url } = element;
            const parsedPath = report_name.replace(/ /g, '-');

            processedReportsData[parsedPath] = report_url;

            reportSidebarData.push({
                id: element.report_sequence,
                name: report_name,
                path: `/Reports/${parsedPath}`,
                rolesCanAccess: ["system admin", "manager", "lead role", "finance", "treasury"],
            })
        });

        return {
            reportSidebarData,
            processedReportsData
        };
    }
)

export const reportsSlice = createSlice({
    name: 'allReportsLink',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllReportsLinks.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getAllReportsLinks.fulfilled, (state, action) => {
            console.log("reportSidebarData", action.payload.reportSidebarData);
            state.isLoading = false;
            state.allReportsLink = action.payload.processedReportsData;
            state.reportSidebarData = action.payload.reportSidebarData;
            state.error = null;
        })
        builder.addCase(getAllReportsLinks.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })
    },
})

export default reportsSlice.reducer;
